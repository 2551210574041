import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import {useLocation } from "react-router-dom"

import NumberFormat from "react-number-format"

import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button"
import { ConvertDataToTableMainPage } from "./SummaryNumberUtils"
import { Typography } from "@material-ui/core"
import { ax } from "hooks/UseLoaderAxios"
import { openInNewTab } from "utils/PageUtils"

export const RunNumber = () => {
    const location = useLocation()

    const tableHead = ["#", "ตัวเลข", "จำนวนเงิน"];
    const [dataTwoRunUp, setDataTwoRunUp] = useState([]);
    const [dataTwoRunDown, setDataTwoRunDown] = useState([]);
    const [dataRunThree, setDataRunThree] = useState([]);

    const loadData = () => {
        ax.get("/dashboard/user/sum-number-run").then((res) => {
            const datas = res.data

            let twoRunUpResult = ConvertDataToTableMainPage(datas.run2Up.items);
            twoRunUpResult.push(
                [<Typography variant="button" gutterBottom>รวม</Typography>,
                "",
                <NumberFormat value={datas.run2Up.totalAmount} displayType={'text'}
                    thousandSeparator={true}
                    renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />
                ])
            setDataTwoRunUp(twoRunUpResult)

            let twoRunDownResult = ConvertDataToTableMainPage(datas.run2Down.items)
            twoRunDownResult.push(
                [<Typography variant="button" gutterBottom>รวม</Typography>,
                "",
                <NumberFormat value={datas.run2Down.totalAmount} displayType={'text'}
                    thousandSeparator={true}
                    renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />
                ])
            setDataTwoRunDown(twoRunDownResult)

            let runThreeResult = ConvertDataToTableMainPage(datas.run3.items)
            runThreeResult.push(
                [<Typography variant="button" gutterBottom>รวม</Typography>,
                "",
                <NumberFormat value={datas.run3.totalAmount} displayType={'text'}
                    thousandSeparator={true}
                    renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />])
            setDataRunThree(runThreeResult)
        }).catch((err) => {
            console.error(err);
        })
    }

    const btnSummary = (page) => {
        let pageUrl = '';
        switch (page) {
            case 'keep':
                pageUrl = '/keep'
                break
            case 'export':
                pageUrl = '/export'
                break
            default:
                pageUrl = '/keep'

        }
        openInNewTab(location.pathname + pageUrl)
        // history.push(location.pathname + pageUrl)
    }
    useEffect(loadData, [])

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4>สรุป เลขวิ่ง</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
                                    <Button color="success" size="lg" className={"m-r-20"} onClick={() => { btnSummary('keep') }}>
                                        สรุปส่วนตัดเก็บ
                                    </Button>
                                    <Button color="danger" size="lg" onClick={() => { btnSummary('export') }}>
                                        สรุปส่วนตัดส่ง
                                    </Button>
                                </GridItem>
                                <GridItem xs={4} sm={4} md={4}>
                                    <Card>
                                        <CardHeader color="success" className="text-center">
                                            <h6>วิ่งสองตัวบน</h6>
                                        </CardHeader>
                                        <CardBody>
                                            <Table
                                                tableHeaderColor="primary"
                                                tableHead={tableHead}
                                                tableData={dataTwoRunUp}>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                                <GridItem xs={4} sm={4} md={4}>
                                    <Card>
                                        <CardHeader color="danger" className="text-center">
                                            <h6>วิ่งสองตัวล่าง</h6>
                                        </CardHeader>
                                        <CardBody>
                                            <Table
                                                tableHeaderColor="primary"
                                                tableHead={tableHead}
                                                tableData={dataTwoRunDown}>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                                <GridItem xs={4} sm={4} md={4}>
                                    <Card>
                                        <CardHeader color="rose" className="text-center">
                                            <h6>วิ่งสามตัว</h6>
                                        </CardHeader>
                                        <CardBody>
                                            <Table
                                                tableHeaderColor="primary"
                                                tableHead={tableHead}
                                                tableData={dataRunThree}>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>

                </GridItem>
            </GridContainer>
        </>
    )
}