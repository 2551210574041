
var userInfo = null
const UserInfoService = {
  set: (data) => {
    userInfo = data
    localStorage.setItem("ui", btoa(JSON.stringify(data)))
  },
  get: () => {
    if (!userInfo) {
      const data = localStorage.getItem("ui")
      const userInfoStr = atob(data)
      userInfo = JSON.parse(userInfoStr)
    }
    return userInfo
  },
  isAdmin: () => {
    const ui = UserInfoService.get()
    if (ui.role === 'ADM') {
      return true
    }

    return false
  }
}



export default UserInfoService