import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { makeStyles, TextField } from "@material-ui/core";

import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Button from "../../components/CustomButtons/Button";
import CardFooter from "../../components/Card/CardFooter";
import cardStyles from "../../assets/jss/component/cardStyle";
import  ToastAlert  from "../../components/ToastAlert/ToastAlert";
import { ax } from "hooks/UseLoaderAxios";

const useStyle = makeStyles((theme) => ({
    cardTitleWhite: cardStyles.cardTitleWhite,
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));

export const LottoConfig = () => {
    const classes = useStyle();
    const [showAlertSuccess, setShowAlertSuccess] = useState(false)
    const [showAlertErr, setShowAlertErr] = useState(false)
    const [toastMessage, setToastMessage] = useState("")

    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            config3Up: 0,
            config3Down: 0,
            config3Tod: 0,
            config3Run: 0,
            config2Up: 0,
            config2Down: 0,
            config2UpRun: 0,
            config2DownRun: 0,
        }
    })

    const loadConfig = () => {
        ax.get('/lottoconfigs/owner').then((res) => {
            const data = res.data;
            setValue('config3Up', data.config3Up)
            setValue('config3Down', data.config3Down)
            setValue('config3Tod', data.config3Tod)
            setValue('config3Run', data.config3Run)
            setValue('config2Up', data.config2Up)
            setValue('config2Down', data.config2Down)
            setValue('config2UpRun', data.config2UpRun)
            setValue('config2DownRun', data.config2DownRun)
        }).catch((err) => {
            console.error(err.response)
        })
    }

    const saveConfig = (data) => {
        let reqForm = {
            config3Up: parseInt(data.config3Up),
            config3Down: parseInt(data.config3Down),
            config3Tod: parseInt(data.config3Tod),
            config3Run: parseInt(data.config3Run),
            config2Up: parseInt(data.config2Up),
            config2Down: parseInt(data.config2Down),
            config2UpRun: parseInt(data.config2UpRun),
            config2DownRun: parseInt(data.config2DownRun),
        }
        ax.post('/lottoconfigs/owner', reqForm).then((res)=>{
            setToastMessage("บันทึกข้อมูลสำเร็จ")
            setShowAlertSuccess(true)
        }).catch((err)=>{
            let response = err.response
            console.error(response)
            setToastMessage("เกิดข้อผิดพลาดในการบันทึกข้อมูล: " + response.data.errors.message)
            setShowAlertErr(true)
        })
    }

    const handleShowAlertSuccess = ()=>{
        setShowAlertSuccess(false)
    }

    const handleShowAlertErr = ()=>{
        setShowAlertErr(false)
    }

    useEffect(loadConfig, [])

    return (
        <>
        <GridContainer></GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                    <Card>
                        <form className={classes.formControl} onSubmit={handleSubmit(saveConfig)}>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>ตั้งค่าตัดเก็บรายตัว</h4>
                            </CardHeader>
                            <CardBody>

                                <TextField
                                    name="config3Up"
                                    variant="outlined"
                                    label="สามตัวบน"
                                    fullWidth
                                    type="number"
                                    margin="normal"
                                    inputRef={register} />
                                <TextField
                                    name="config3Down"
                                    variant="outlined"
                                    label="สามตัวล่าง"
                                    fullWidth
                                    type="number"
                                    margin="normal"
                                    inputRef={register} />
                                <TextField
                                    name="config3Tod"
                                    variant="outlined"
                                    label="สามตัวโต๊ด"
                                    fullWidth
                                    type="number"
                                    margin="normal"
                                    inputRef={register} />
                                <TextField
                                    name="config3Run"
                                    variant="outlined"
                                    label="สามตัววิ่ง"
                                    fullWidth
                                    type="number"
                                    margin="normal"
                                    inputRef={register} />
                                <TextField
                                    name="config2Up"
                                    variant="outlined"
                                    label="สองตัวบน"
                                    fullWidth
                                    type="number"
                                    margin="normal"
                                    inputRef={register} />
                                <TextField
                                    name="config2Down"
                                    variant="outlined"
                                    label="สองตัวล่าง"
                                    fullWidth
                                    type="number"
                                    margin="normal"
                                    inputRef={register} />
                                <TextField
                                    name="config2UpRun"
                                    variant="outlined"
                                    label="วิ่งสองตัวบน"
                                    fullWidth
                                    type="number"
                                    margin="normal"
                                    inputRef={register} />
                                <TextField
                                    name="config2DownRun"
                                    variant="outlined"
                                    label="วิ่งสองตัวล่าง"
                                    fullWidth
                                    type="number"
                                    margin="normal"
                                    inputRef={register} />

                            </CardBody>
                            <CardFooter>
                                <Button color="primary" type="submit" >บันทึก</Button>
                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
            <ToastAlert message={toastMessage} open={showAlertSuccess} onClose={handleShowAlertSuccess}/>
            <ToastAlert message={toastMessage} open={showAlertErr} onClose={handleShowAlertErr} severity={"error"} />
        </>
    );
}