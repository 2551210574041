import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import menu from "menu.js"

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/lottery_logo.png";
import {useLoaderAxios} from "hooks/UseLoaderAxios";
import Loader from "components/Loader/Loader";
import { RefreshToken } from "services/AuthenService";

let ps;

const createRoute = (prop, key, path) => {
    return (
        <Route path={path}
            component={prop.component}
            key={key}></Route>
    )
}
const createRouteNest = (prop, key) =>{
    if (prop.context) {
        return (
            <Route path={prop.layout + prop.path}
                key={key}
                render={({ match: { url } }) => (
                    <prop.context>
                        <Route path={`${url}/`} component={prop.component} exact />
                        {prop.routes.map((childProp, childKey) => {
                            return createRoute(childProp, childKey, prop.layout + childProp.path)
                        })}
                    </prop.context>
                )} />
        )
    } else {
        return (
            <Route path={prop.layout + prop.path}
                key={key}
                render={({ match: { url } }) => (
                    <>
                        <Route path={`${url}/`} component={prop.component} exact />
                        {prop.routes.map((childProp, childKey) => {
                            return createRoute(childProp, childKey, prop.layout + childProp.path)
                        })}
                    </>
                )} />
        )
    }
}
const switchRoutes = (
    <Switch>
        {routes.map((prop, key) => {
            if (prop.layout === "/user") {
                if (prop.routes) {
                    return createRouteNest(prop, key)
                } else {
                    return createRoute(prop, key, prop.layout + prop.path);
                }
            }
            return null;
        })}
        <Redirect to="/404"/>
        <Redirect from="/user" to="/user/dashboard" />
    </Switch>
);

const useStyles = makeStyles(styles);

export default function User({ ...rest }) {
    const [loading] = useLoaderAxios();
    // styles
    const classes = useStyles();
    // ref to help us initialize PerfectScrollbar on windows devices
    const mainPanel = React.createRef();
  
    // states and functions
    // const [image, setImage] = React.useState(bgImage);
    // const [color, setColor] = React.useState("blue");
    // const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
    // const [fixedClasses, setFixedClasses] = React.useState("dropdown");
    const [mobileOpen, setMobileOpen] = React.useState(false);
    // const handleImageClick = image => {
    //     setImage(image);
    // };
    // const handleColorClick = color => {
    //     setColor(color);
    // };
    // const handleFixedClick = () => {
    //     if (fixedClasses === "dropdown") {
    //         setFixedClasses("dropdown show");
    //     } else {
    //         setFixedClasses("dropdown");
    //     }
    // };
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const getRoute = () => {
        return window.location.pathname !== "/user/maps";
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };
    // initialize and destroy the PerfectScrollbar plugin
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
            document.body.style.overflow = "hidden";
        }
        window.addEventListener("resize", resizeFunction);
        // Specify how to clean up after this effect:
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
            window.removeEventListener("resize", resizeFunction);
        };
    }, [mainPanel]);

    React.useEffect(()=>{
        const interval = setInterval(()=>{
            RefreshToken()
        }, 180000)

        return ()=>clearInterval(interval)
    },[])

    return (
      <>
        <div className={`wrapper-print ${classes.wrapper}`}>
            <Sidebar
                routes={menu}
                logoText={"Lottery"}
                logo={logo}
                image={bgImage}
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
                color={'blue'}
                {...rest}
            />
            <div className={classes.mainPanel} ref={mainPanel}>
                <Navbar
                    routes={routes}
                    handleDrawerToggle={handleDrawerToggle}
                    {...rest}
                />
                {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                {getRoute() ? (
                    <div className={`print-np ${classes.content}`}>
                        <div className={classes.container}>{switchRoutes}</div>
                    </div>
                ) : (
                        <div className={classes.map}>{switchRoutes}</div>
                    )}
                {getRoute() ? <Footer /> : null}
                {/* <FixedPlugin
                    handleImageClick={handleImageClick}
                    handleColorClick={handleColorClick}
                    bgColor={color}
                    bgImage={image}
                    handleFixedClick={handleFixedClick}
                    fixedClasses={fixedClasses}
                /> */}
            </div>
        </div>

        {loading ? <Loader/> : null}
        </>
    );
}
