import { useLayoutEffect } from "react";
import urljoin from 'url-join';
import Axios from 'axios';

const { useMemo } = require("react")
const { useCallback } = require("react")
const { useState } = require("react")

//120000 = 2min
const ax = Axios.create({timeout: 120000})

const useLoaderAxios = () => {
  const [counter, setCounter] = useState(0)
  const inc = useCallback(() => setCounter(counter => counter + 1), [setCounter])
  const dec = useCallback(() => setCounter(counter => counter - 1), [setCounter])

  const interceptors = useMemo(() => ({
    request: config => {
      inc()
      const token = localStorage.getItem("token");
      const apiUrl = process.env.REACT_APP_API_URL;
      config.url = urljoin(apiUrl, config.url);
      config.crossdomain = true;
      if (token) {
        config.headers['Authorization'] = "Bearer " + token
      }

      return config;
    },
    response: response => {
      dec()
      return response
    },
    error: error => {
      dec()
      if (error.request.status !== 0) {
        let response = error.response
        let statusCode = response.status
        if (statusCode === 401) {
          localStorage.clear()
          window.location.href = window.location.origin + '/login';
        } else if (statusCode === 404) {
          window.location.href = window.location.origin + '/404';
          // response.data= {
          //     errors: {
          //         message : response.statusText,
          //         code : statusCode
          //     }
          // }
        }
        throw error
      }
    },
  }), [inc, dec]); // create the interceptors

  useLayoutEffect(() => {
    // add request interceptors
    const reqInterceptor = ax.interceptors.request.use(interceptors.request, interceptors.error);

    // add response interceptors
    const resInterceptor = ax.interceptors.response.use(interceptors.response, interceptors.error);

    return () => {
      // remove all intercepts when done
      ax.interceptors.request.eject(reqInterceptor);
      ax.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);
  return [counter > 0];
}

export { useLoaderAxios, ax };