import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

import NumberFormat from "react-number-format"

import GridContainer from "components/Grid/GridContainer"
import GridItem from 'components/Grid/GridItem'
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"

import { ConvertDataToTableMainPage } from "./SummaryNumberUtils"
import { Typography } from '@material-ui/core'
import { ax } from 'hooks/UseLoaderAxios';
import { openInNewTab } from 'utils/PageUtils';


export const TwoNumber = () => {
  const location = useLocation()
  const tableHead = ["#", "ตัวเลข", "จำนวนเงิน"];

  const [number2UpData, setNumber2UpData] = useState([])
  const [number2DownData, setNumber2DownData] = useState([])

  const loadSummaryTwoNumber = () => {
    ax.get('/dashboard/user/sum-number-two').then(res => {
      const datas = res.data
      let number2UpResult = ConvertDataToTableMainPage(datas.number2Up.items);
      number2UpResult.push(
        [<Typography variant="button" gutterBottom>รวม</Typography>,
        "",
        <NumberFormat value={datas.number2Up.totalAmount}
          displayType={'text'} thousandSeparator={true}
          renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />
        ])
      setNumber2UpData(number2UpResult)

      let number2DownResult = ConvertDataToTableMainPage(datas.number2Down.items)
      number2DownResult.push(
        [<Typography variant="button" gutterBottom>รวม</Typography>,
        "",
        <NumberFormat value={datas.number2Down.totalAmount} displayType={'text'}
          thousandSeparator={true}
          renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />
        ])
      setNumber2DownData(number2DownResult)
    }).catch(err => {
      console.error(err.response)
    })
  }

  const btnChangePage = (page) => {
    let pageUrl = '';

    switch (page) {
      case 'keep':
        pageUrl = location.pathname + "/keep"
        break
      case 'export':
        pageUrl = location.pathname + "/export"
        break
      default:
        pageUrl = location.pathname
    }
    
    openInNewTab(pageUrl)
  }
  useEffect(loadSummaryTwoNumber, [])

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4>สรุป เลขสองตัว</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
                  <Button color="success" size="lg" className={"m-r-20"} onClick={() => { btnChangePage('keep') }}>
                    สรุปส่วนตัดเก็บ
                                    </Button>
                  <Button color="danger" size="lg" onClick={() => { btnChangePage('export') }}>
                    สรุปส่วนตัดส่ง
                                    </Button>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <Card>
                    <CardHeader color="success" className="text-center">
                      <h6>สองตัวบน</h6>
                    </CardHeader>
                    <CardBody>
                      <Table
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={number2UpData}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <Card>
                    <CardHeader color="danger" className="text-center">
                      <h6>สองตัวล่าง</h6>
                    </CardHeader>
                    <CardBody>
                      <Table
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={number2DownData}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  )
}