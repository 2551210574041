import React, { useEffect } from 'react'
import { useState } from 'react'

import NumberFormat from 'react-number-format'
import { TextField, Typography } from '@material-ui/core'

import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from "components/CustomButtons/Button"
import Table from "components/Table/Table.js";
import { ConvertDataToTableBody } from './SummaryNumberUtils'
import { ax } from '../../hooks/UseLoaderAxios'
import { useHistory } from 'react-router-dom'
import ConfirmInfo from 'components/ConfirmDialog/ConfirmInfo'
import ToastAlert from 'components/ToastAlert/ToastAlert'
import { Autocomplete } from '@material-ui/lab'
import { useRef } from 'react'
import Danger from 'components/Typography/Danger'

export const RunNumberExport = () => {
  const tableHead = ["#", "ตัวเลข", "จำนวนเงิน"]
  const history = useHistory()
  const [number2UpBody, setNumber2RunUpBody] = useState([])
  const [number2RunDownBody, setNumber2RunDownBody] = useState([])
  const [number3RunBody, setNumber3RunBody] = useState([])
  const [custData, setCustData] = useState([])
  const [custSelect, setCustSelect] = useState('')
  const [exportType, setExportType] = useState('')
  const [open, setOpen] = useState(false)
  const custNumber2RunUp = useRef(null)
  const custNumber2RunDown = useRef(null)
  const custNumber3Run = useRef(null)
  const [errMsg, setErrMsg] = useState('')
  const [toatAlertErr, setToatAlertErr] = useState(false)
  const [number2RunUpDataStore, setNumber2RunUpDataStore] = useState([])
  const [number2RunDownDataStore, setNumber2RunDownDataStore] = useState([])
  const [number3RunDataStore, setNumber3RunDataStore] = useState([])

  const loadCustomer = () => {
    ax.get('/customers-list').then((res) => {
      setCustData(res.data)
    }).catch((err) => {
      console.log(err.response)
    })
  }

  const loadSumData = () => {
    ax.post('/lottodatas/summary', {
      "lottoType": "LOTTO_RUN_NUMBER",
      "riskType": "EXPORT"
    }).then(res => {
      const datas = res.data

      setNumber2RunUpDataStore(datas.number2RunUp)
      let number2RunUpTbl = ConvertDataToTableBody(datas.number2RunUp)
      let number2RunUpData = number2RunUpTbl.bodys
      number2RunUpData.push(
        [<Typography variant="button" gutterBottom>รวม</Typography>,
          "",
        <NumberFormat value={number2RunUpTbl.totalAmount} displayType={'text'}
          thousandSeparator={true}
          renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>}
        />
        ])
      setNumber2RunUpBody(number2RunUpData)

      setNumber2RunDownDataStore(datas.number2RunDown)
      let number2RunDownTbl = ConvertDataToTableBody(datas.number2RunDown)
      let number2RunDownData = number2RunDownTbl.bodys
      number2RunDownData.push(
        [<Typography variant="button" gutterBottom>รวม</Typography>,
          "",
        <NumberFormat value={number2RunDownTbl.totalAmount} displayType={'text'}
          thousandSeparator={true}
          renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>}
        />
        ])
      setNumber2RunDownBody(number2RunDownData)

      setNumber3RunDataStore(datas.number3Run)
      let number3RunTbl = ConvertDataToTableBody(datas.number3Run)
      let number3RunData = number3RunTbl.bodys
      number3RunData.push(
        [<Typography variant="button" gutterBottom>รวม</Typography>,
          "",
        <NumberFormat value={number3RunTbl.totalAmount} displayType={'text'}
          thousandSeparator={true}
          renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>}
        />
        ])
      setNumber3RunBody(number3RunData)
    }).catch(err => {
      console.error(err.response)
    })
  }

  const exportExcel = () => {
    ax.post('/lottodatas/summary-export', {
      "lottoType": "LOTTO_RUN_NUMBER",
      "riskType": "EXPORT"
    }, {
      responseType: 'blob'
    }).then(res => {
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', 'สรุปยอดตัดส่งเลขวิ่ง.xlsx')
      document.body.appendChild(link)
      link.click()
      link.remove()
    }).catch(err => {
      console.error(err.response)
    })
  }

  const createLottoData = () => {
    const dataBody = {
      "lottoType": "",
      "custName": "",
      "custID": "",
      "items": []
    }
    if (exportType === 'number2RunUp') {
      const items = number2RunUpDataStore.map(i => {
        return { 'lottoNumber': i.lottoNumber, 'amount': -1 * i.amount }
      })
      dataBody.lottoType = "2RUNUP"
      dataBody.items = items
    } else if (exportType === 'number2RunDown') {
      const items = number2RunDownDataStore.map(i => {
        return { 'lottoNumber': i.lottoNumber, 'amount': -1 * i.amount }
      })
      dataBody.lottoType = "2RUNDOWN"
      dataBody.items = items
    } else {
      const items = number3RunDataStore.map(i => {
        return { 'lottoNumber': i.lottoNumber, 'amount': -1 * i.amount }
      })
      dataBody.lottoType = "3RUN"
      dataBody.items = items
    }

    if (!custSelect) {
      setErrMsg("กรุณาเลือกคนเดินโพย")
      setToatAlertErr(true)
      return
    }

    dataBody.custID = custSelect.ID
    dataBody.custName = custSelect.custName

    if (dataBody.items.length > 0) {
      ax.post('/lottodatas/list', dataBody).then((res) => {
        setCustSelect(null);
        clearAutoSelect(dataBody.lottoType);
        clearDataTable(dataBody.lottoType);
      }).catch((err) => {
        console.log(err.response)
        let response = err.response
        setErrMsg(response.data.errors.message)
        setToatAlertErr(true)
      })
    }
  }

  const clearDataTable = (exportType) => {
    if (exportType === '2RUNUP') {
      setNumber2RunUpBody([])
    } else if(exportType === '2RUNDOWN'){
      setNumber2RunDownBody([])
    }else{
      setNumber3RunBody([])
    }
}

  const clearAutoSelect = (lottoType) => {
    if (exportType === 'number2RunUp') {
      custNumber2RunUp.current.childNodes[0].childNodes[1].childNodes[1].childNodes[0].click();
    } else if (exportType === 'number2RunDown') {
      custNumber2RunDown.current.childNodes[0].childNodes[1].childNodes[1].childNodes[0].click();
    } else {
      custNumber3Run.current.childNodes[0].childNodes[1].childNodes[1].childNodes[0].click();
    }
  }

  useEffect(loadCustomer, [])
  useEffect(loadSumData, [])
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="danger">
              <h4>สรุปยอดตัดส่ง เลขวิ่ง</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }} className="print-hide">
                  <Button color="rose" size="lg" className={'m-r-20 card-print'} onClick={() => { loadSumData() }}>โหลดข้อมูล</Button>
                  <Button color="success" size="lg" className={'m-r-20 card-print'} onClick={() => { window.print() }}>พิมพ์</Button>
                  <Button color="primary" size="lg" className={"m-r-20 card-print"} onClick={() => { exportExcel() }}>
                    พิมพ์ Excel
                  </Button>
                  <Button color="info" size="lg" className={"m-r-20 card-print"} onClick={() => { history.push("/user/summary/runnumber") }}>
                    กลับ
                  </Button>
                </GridItem>

                <GridItem xs={4} sm={4} md={4} className="print-np">
                  <Card>
                    <CardHeader color="success" className="text-center">
                      <h6>วิ่งสองตัวบน</h6>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem className="print-hide">
                          <Button color="danger"
                            onClick={() => {
                              setExportType('number2RunUp')
                              setOpen(true)
                            }}>ตัดยอดเงิน</Button>
                        </GridItem>

                        <GridItem xs={7} sm={7} md={7} className="print-hide">
                          <Autocomplete
                            id="combo-box-demo2"
                            ref={custNumber2RunUp}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setCustSelect(newValue);
                              }
                            }}
                            options={custData}
                            getOptionLabel={(option) => option.custName}
                            getOptionSelected={(option) => option.ID}
                            renderInput={(params) => <TextField {...params} label="ผู้ซื้อ/คนเดินโพย" variant="outlined" />}
                          />
                        </GridItem>
                      </GridContainer>
                      <Table
                        panelClass="print-tbl"
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={number2UpBody}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} className="print-np">
                  <Card>
                    <CardHeader color="danger" className="text-center">
                      <h6>วิ่งสองตัวล่าง</h6>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem className="print-hide">
                          <Button color="danger"
                            onClick={() => {
                              setExportType('number2RunDown')
                              setOpen(true)
                            }}>ตัดยอดเงิน</Button>
                        </GridItem>

                        <GridItem xs={7} sm={7} md={7} className="print-hide">
                          <Autocomplete
                            id="combo-box-demo2"
                            ref={custNumber2RunDown}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setCustSelect(newValue);
                              }
                            }}
                            options={custData}
                            getOptionLabel={(option) => option.custName}
                            getOptionSelected={(option) => option.ID}
                            renderInput={(params) => <TextField {...params} label="ผู้ซื้อ/คนเดินโพย" variant="outlined" />}
                          />
                        </GridItem>
                      </GridContainer>
                      <Table
                        panelClass="print-tbl"
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={number2RunDownBody}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} className="print-np">
                  <Card>
                    <CardHeader color="rose" className="text-center">
                      <h6>วิ่งสามตัว</h6>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem className="print-hide">
                          <Button color="danger"
                            onClick={() => {
                              setExportType('number3Run')
                              setOpen(true)
                            }}>ตัดยอดเงิน</Button>
                        </GridItem>

                        <GridItem xs={7} sm={7} md={7} className="print-hide">
                          <Autocomplete
                            id="combo-box-demo2"
                            ref={custNumber3Run}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setCustSelect(newValue);
                              }
                            }}
                            options={custData}
                            getOptionLabel={(option) => option.custName}
                            getOptionSelected={(option) => option.ID}
                            renderInput={(params) => <TextField {...params} label="ผู้ซื้อ/คนเดินโพย" variant="outlined" />}
                          />
                        </GridItem>
                      </GridContainer>
                      <Table
                        panelClass="print-tbl"
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={number3RunBody}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <ConfirmInfo open={open} handleCloseDialog={() => { setOpen(false) }}
          handleCallbak={createLottoData}
          title="ยืนยันตัดยอดส่งออก">
          <Danger>ระบบจะทำการบันทึกข้อมูลยอดส่งออก โดยนำยอดเงินไปลบออกจากยอดที่คีย์มาในระบบ</Danger>
        </ConfirmInfo>
        <ToastAlert message={errMsg} open={toatAlertErr} onClose={() => { setToatAlertErr(false) }} severity={"error"} />
      </GridContainer>

    </>
  )
}
