
import React from 'react'
import PropTypes from "prop-types";

const { FormControl, InputLabel, Select, FormHelperText, makeStyles } = require("@material-ui/core")
const { Controller } = require("react-hook-form")

const classMakeStyle = makeStyles((theme) => ({
  lablePd: {
    paddingLeft: '18px',
    marginTop: '-8px'
  }
}))

const CustomSelect = (props) => {
  const {
    name,
    label,
    control,
    defaultValue = "",
    children,
    errors,
  } = props

  const labelID = `${name}-label`
  const classes = classMakeStyle()
  return (
    <FormControl {...props} >
      <InputLabel id={labelID} className={classes.lablePd}>{label}</InputLabel>
      <Controller 
      as={
        <Select labelId={labelID} label={label}>
          {children}
        </Select>
      }
        name={name}
        control={control}
        defaultValue={defaultValue}
        variant="outlined"
      />
      <FormHelperText>
        {errors[name] && <span className="error">{errors[name].message}</span>}
      </FormHelperText>
    </FormControl>
  )
}

CustomSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  control: PropTypes.object,
  errors: PropTypes.object
}
export default CustomSelect


/* <FormControl fullWidth className={classes.formControl}>
                <CustomSelect
                    name="custID"
                    label="ผู้ซื้อ/คนเดินโพย"
                    control={control}
                    defaultValue=""
                    errors={errors}
                    error={Boolean(errors.custID)}
                >
                    <MenuItem value="" disabled>
                        กรุณาเลือก
                    </MenuItem>
                    {
                        custData.map((data, index) => {
                            return <MenuItem value={data.ID} key={index}>{data.custName}</MenuItem>
                        })
                    }
                </CustomSelect>
                </FormControl> */