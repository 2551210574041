import React, { useState } from 'react';
import './Login.css';
import { useForm } from 'react-hook-form'
import { useHistory } from "react-router-dom";
import UserInfoService from "services/UserInfoService"
import {Link } from '@material-ui/core';
import { ax } from 'hooks/UseLoaderAxios';
import { useLoaderAxios } from 'hooks/UseLoaderAxios';
import Loader from 'components/Loader/Loader';
import { v4 as uuidv4 } from 'uuid';
import { Constant } from '../../Constant';
import ConfirmInfo from 'components/ConfirmDialog/ConfirmInfo';
import Danger from 'components/Typography/Danger';

export default function Login() {
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    const [errMsg, setErrMsg] = useState("")
    const [showPopup, setShowPopup] = useState(false)
    const [loginForm, setLoginForm] = useState({})

    const [loading] = useLoaderAxios()
    const onSubmit = async (data) => {
        try {
            await loginUser(data)
            const userInfo = await getUserInfo()
            if (userInfo && userInfo.role === 'ADM') {
                history.push("/admin/dashboard")
            } else {
                history.push("/user/dashboard")
            }
        } catch (error) {
            setErrMsg(error)
        }
    }

    const [userHasValue, setUserHasValue] = useState("")
    const [passHasValue, setPassHasValue] = useState("")
    const usernameChange = event => {
        if (event.target.value) {
            setUserHasValue('has-val')
        } else {
            setUserHasValue('')
        }
    }

    const passChane = event => {
        if (event.target.value) {
            setPassHasValue('has-val')
        } else {
            setPassHasValue('')
        }
    }

    const loginUser = async (data) => {
        var uuidLogin = localStorage.getItem(Constant.UUID)

        if (!uuidLogin) {
            uuidLogin = uuidv4()
        }
        data['uuid'] = uuidLogin
        await ax.post('/login', data).then((res) => {

            let data = res.data
            if (data) {
                let token = data.access_token
                let refreshToken = data.refresh_token
                localStorage.clear()

                localStorage.setItem(Constant.TOKEN, token)
                localStorage.setItem(Constant.REFRESH_TOKEN, refreshToken)
                localStorage.setItem(Constant.UUID, uuidLogin)
            }
        }).catch((error) => {
            let response = error.response
            let text = "เกิดข้อผิดพลาดในการเชื่อมต่อ กรุณาติดต่อ Admin"
            if (response) {
                if (response.data.code === 'EL03') {
                    text = response.data.code + " : บัญชีนี้ login ครบจำนวนแล้ว (" + response.data.result + " เครื่อง )"
                    setLoginForm(data)
                    setShowPopup(true)
                } else {
                    if (response.data.errors.code === 'EL02') {
                        text = response.data.errors.code + " : User หมดอายุ"
                    } else {
                        text = response.data.errors.code + " : " + response.data.errors.message
                    }
                }
            }
            setErrMsg(text)
            throw text
        });
    }

    const getUserInfo = async () => {
        let userInfo = null
        await ax.get('/myprofile').then(res => {
            userInfo = res.data
            UserInfoService.set(userInfo)
        }).catch(error => {
            let text = error + "<br> เกิดข้อผิดพลาดในการเชื่อมต่อ กรุณาติดต่อ Admin"
            let response = error.response
            if (response) {
                text = response.data.errors.code + " : " + response.data.errors.message
            }
            setErrMsg(text)

            throw text
        })

        return userInfo
    }

    const logoutAll = () => {
        setErrMsg('')
        ax.post('/logoutAll', loginForm).then(res => {
        }).catch(error => {
            let text = error + "<br> เกิดข้อผิดพลาดในการเชื่อมต่อ กรุณาติดต่อ Admin"
            let response = error.response
            if (response) {
                text = response.data.errors.code + " : " + response.data.errors.message
            }
            setErrMsg(text)
            throw text
        })
    }

    return (
        <>
            <div className="limiter">

                <div className="container-login100">
                    <div className="wrap-login100">
                        <form className="login100-form validate-form" onSubmit={handleSubmit(onSubmit)}>
                            <span className="login100-form-title p-b-26">
                                ยินดีต้อนรับ NK Lotto
                            </span>
                            <span className="login100-form-title p-b-48">
                                <i className="zmdi zmdi-font"></i>
                            </span>

                            <div className="wrap-input100 validate-input">
                                <input className={`input100 ${userHasValue}`} type="text"
                                    name="username" ref={register({ required: true })}
                                    onChange={usernameChange} />
                                <span className="focus-input100" data-placeholder="Username"></span>
                                {errors.username && <span className="error">Username is required</span>}
                            </div>

                            <div className="wrap-input100 validate-input" data-validate="Enter password">
                                <span className="btn-show-pass">
                                    <i className="zmdi zmdi-eye"></i>
                                </span>
                                <input className={`input100 ${passHasValue}`} type="password"
                                    name="password" ref={register({ required: true })}
                                    onChange={passChane} />
                                <span className="focus-input100" data-placeholder="Password"></span>
                                {errors.password && <span className="error">Password is required</span>}
                            </div>

                            <div className="container-login100-form-btn">
                                <div className="wrap-login100-form-btn">
                                    <div className="login100-form-bgbtn"></div>
                                    <button className="login100-form-btn" type="submit">
                                        Login
                                    </button>
                                </div>
                            </div>
                            <div className="text-center p-t-10">
                                <span className="txt1 error">
                                    {errMsg}
                                </span>
                            </div>

                            <div className="text-center p-t-115">
                                <span className="txt1">
                                    <Link href="/register">สมัครสมาชิก</Link>
                                </span>
                                <br />
                                <span>

                                    <Link
                                        href="https://www.facebook.com/%E0%B8%88%E0%B8%B1%E0%B8%94%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B9%80%E0%B8%AA%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%87%E0%B8%A5%E0%B9%87%E0%B8%AD%E0%B8%95%E0%B9%80%E0%B8%95%E0%B8%AD%E0%B8%A3%E0%B8%B5%E0%B9%88-109121444776736"
                                        target="_blank"
                                    > <b>Facebook Page จัดการความเสี่ยงล็อตเตอรี่</b>
                                    </Link>
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {loading ? <Loader /> : null}
            <ConfirmInfo open={showPopup} handleCloseDialog={() => { setShowPopup(false) }}
                handleCallbak={logoutAll}
                title="ยืนยัน Logout ผู้ใช้งานทั้งหมด">
                <p><Danger>{errMsg}</Danger></p>
                <Danger>คุณต้องการที่จะ Logout ผู้ใช้งานทั้งหมดออกจากระบบหรือไม่</Danger>
            </ConfirmInfo>
        </>
    )
}
