import React, { useEffect, useState } from 'react'

import NumberFormat from 'react-number-format'
import { Typography } from '@material-ui/core'

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button"
import { ConvertDataToTableBody } from './SummaryNumberUtils'
import { ax } from 'hooks/UseLoaderAxios'
import { useHistory } from 'react-router-dom'

export const RunNumberKeep = () => {
  const history = useHistory()
  const tableHead = ["#", "ตัวเลข", "จำนวนเงิน"]
  const [number2UpBody, setNumber2RunUpBody] = useState([])
  const [number2RunDownBody, setNumber2RunDownBody] = useState([])
  const [number3RunBody, setNumber3RunBody] = useState([])

  const loadSumData = () => {
    ax.post('/lottodatas/summary', {
      "lottoType": "LOTTO_RUN_NUMBER",
      "riskType": "KEEP"
    }).then(res => {
      const datas = res.data

      let number2RunUpResult = ConvertDataToTableBody(datas.number2RunUp)
      let number2RunUpData = number2RunUpResult.bodys
      number2RunUpData.push(
        [<Typography variant="button" gutterBottom>รวม</Typography>,
          "",
        <NumberFormat value={number2RunUpResult.totalAmount} displayType={'text'}
          thousandSeparator={true}
          renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />
        ])
      setNumber2RunUpBody(number2RunUpData)

      let number2RunDownResult = ConvertDataToTableBody(datas.number2RunDown)
      let number2RunDownData = number2RunDownResult.bodys
      number2RunDownData.push(
        [<Typography variant="button" gutterBottom>รวม</Typography>,
          "",
        <NumberFormat value={number2RunDownResult.totalAmount} displayType={'text'}
          thousandSeparator={true}
          renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />
        ])
      setNumber2RunDownBody(number2RunDownData)

      let number3RunResult = ConvertDataToTableBody(datas.number3Run)
      let number3RunData = number3RunResult.bodys
      number3RunData.push(
        [<Typography variant="button" gutterBottom>รวม</Typography>,
          "",
        <NumberFormat value={number3RunResult.totalAmount} displayType={'text'}
          thousandSeparator={true}
          renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />
        ])
      setNumber3RunBody(number3RunData)
    }).catch(err => {
      console.error(err.response)
    })
  }

  const exportExcel = () => {
    ax.post('/lottodatas/summary-export', {
      "lottoType": "LOTTO_RUN_NUMBER",
      "riskType": "KEEP"
    }, {
      responseType: 'blob'
    }).then(res => {
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', 'สรุปยอดตัดเก็บเลขวิ่ง.xlsx')
      document.body.appendChild(link)
      link.click()
      link.remove()
    }).catch(err => {
      console.error(err.response)
    })
  }

  useEffect(loadSumData, [])

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} className="print-np">
          <Card>
            <CardHeader color="primary">
              <h4>สรุปยอดตัดเก็บ เลขวิ่ง</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }} className="print-hide">
                  <Button color="success" size="lg" className={'m-r-20 card-print'} onClick={() => { window.print() }}>พิมพ์</Button>
                  <Button color="primary" size="lg" className={"m-r-20 card-print"} onClick={() => { exportExcel() }} >
                    พิมพ์ Excel
                  </Button>
                  <Button color="info" size="lg" className={"m-r-20 card-print"} onClick={() => { history.push("/user/summary/runnumber") }}>
                    กลับ
                  </Button>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} className="print-np">
                  <Card>
                    <CardHeader color="success" className="text-center">
                      <h6>วิ่งสองตัวบน</h6>
                    </CardHeader>
                    <CardBody>
                      <Table
                        panelClass="print-tbl"
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={number2UpBody}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} className="print-np">
                  <Card>
                    <CardHeader color="danger" className="text-center">
                      <h6>วิ่งสองตัวล่าง</h6>
                    </CardHeader>
                    <CardBody>
                      <Table
                        panelClass="print-tbl"
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={number2RunDownBody}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} className="print-np">
                  <Card>
                    <CardHeader color="rose" className="text-center">
                      <h6>วิ่งสามตัว</h6>
                    </CardHeader>
                    <CardBody>
                      <Table
                        panelClass="print-tbl"
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={number3RunBody}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

      </GridContainer>
    </>
  )
}