const InputAutoFocus = (e) => {
    if (e.keyCode === 13) {
        const form = e.target.form

        if (!form) {
            return
        }
        let index = Array.prototype.indexOf.call(form, e.target);
        let isNotInput = true
        while (isNotInput) {
            if (!form.elements[index + 1]) {
                return;
            }
            const inputType = form.elements[index + 1].type

            if (inputType && inputType !== 'fieldset' && inputType !== 'submit') {
                const parentEl = form.elements[index + 1].parentNode.parentNode
                const elClassName = parentEl.className
                const isHidden = elClassName.includes('hidden-field')

                if (!isHidden) {
                    isNotInput = false
                    form.elements[index + 1].focus()
                    form.elements[index + 1].select()
                }

            } else if (inputType === 'submit') {
                isNotInput = false
                form.elements[index + 1].click()
            }
            index++
        }
        e.preventDefault()
    }
}
export default InputAutoFocus