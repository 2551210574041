/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import Login from "views/Login/Login.js";

import "assets/css/material-dashboard-react.css?v=1.8.0";
import "assets/css/app.css?v=1.0";
import PrivateRoute from "components/PrivateRoute/PrivateRoute.js";
import { PageNotFound } from "views/PageError/PageNotFound";
import User from "layouts/User";
import { Register } from "views/Register/Register";

const hist = createBrowserHistory();

ReactDOM.render(
    <Router history={hist}>
        <Switch>
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register}/>
            <Route path="/404" component={PageNotFound}/>
            <PrivateRoute path="/admin" component={Admin} />
            <PrivateRoute path="/user" component={User} />
            <Redirect from="/" to="/user/dashboard" />
        </Switch>
    </Router>,
    document.getElementById("root")
);
