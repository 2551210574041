import React, { useRef, useState } from "react"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import CardBody from "components/Card/CardBody"
import { FormHelperText, TextField } from "@material-ui/core"
import { useForm } from "react-hook-form"
import RegularButton from "components/CustomButtons/Button"
import { ax } from "hooks/UseLoaderAxios"
import ToastAlert from "components/ToastAlert/ToastAlert"

export const ChangePasswordDeleteData = () => {
    const { register, errors, handleSubmit, watch } = useForm()
    const [severity, setSeverity] = useState('success')
    const [toastMsg, setToastMsg] = useState('')
    const [openSaveDeletePass, setOpenSaveDeletePass] = useState(false)
    const password = useRef({})
    password.current = watch('password', '')

    const submitChangePassword = (form) =>{
        ax.put('/myprofile/password-delete',{
            'currentPassword': form.oldPass,
            'newPassword': form.password
        }).then(() => {
            setToastMsg("เปลี่ยนรหัสผ่านสำเร็จ")
            setSeverity("success")
            setOpenSaveDeletePass(true)
        }).catch((err) => {
            let response = err.response
            if (response) {
                setToastMsg(response.data.errors.message)
            } else {
                setToastMsg("เกิดข้อผิดพลาด")
            }
            setSeverity("error")
            setOpenSaveDeletePass(true)
        })

    }

    return (
        <>
            <GridContainer justifyContent="center">
                <GridItem xs={12} sm={12} md={8}>
                    <Card>
                        <CardHeader color="primary" className="text-center">
                            <h4>เปลี่ยนรหัสผ่าน ลบข้อมูล</h4>
                        </CardHeader>
                        <CardBody>
                            <form id="change-pass-del" onSubmit={handleSubmit(submitChangePassword)}>
                                <TextField name="oldPass" variant="outlined" label="รหัสผ่านเดิม"
                                    inputRef={register({ required: true })}
                                    fullWidth
                                    type="password"
                                    margin="normal" />
                                <FormHelperText>{errors.oldPass && <span className="error">กรุณากรอก รหัสผ่านเดิม</span>}</FormHelperText>
                                <TextField name="password" variant="outlined" label="รหัสผ่านใหม่"
                                    inputRef={register({ required: true })}
                                    fullWidth
                                    type="password"
                                    margin="normal"
                                />
                                <FormHelperText>{errors.passDel && <span className="error">กรุณากรอก รหัสผ่านใหม่</span>}</FormHelperText>
                                <TextField name="passDelConfirm" variant="outlined" label="ยืนยันรหัสผ่าน"
                                    inputRef={register({ 
                                        validate: value => value === password.current || "รหัสผ่านไม่ตรงกัน"
                                     })} 
                                    fullWidth
                                    type="password"
                                    margin="normal"/>
                                <FormHelperText>{errors.passDelConfirm && <span className="error">{errors.passDelConfirm.message}</span>}</FormHelperText>
                                <RegularButton color="primary" size="lg" type="submit">ยืนยัน</RegularButton>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <ToastAlert message={toastMsg} open={openSaveDeletePass} onClose={() => {
                setOpenSaveDeletePass(false)
            }} severity={severity}/>
        </>
    )

}