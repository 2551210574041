import React, { useEffect, useState } from 'react'
// material-ui icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";

//component import
import Table from "components/Table/Table.js"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form'
import IconButton from "@material-ui/core/IconButton";


import ToastAlert from 'components/ToastAlert/ToastAlert';
import { Pagination } from '@material-ui/lab';

//Style
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import pageStyle from 'assets/jss/component/paginationStyle.js'
import { rowNumberUtils } from 'utils/PageUtils';
import ConfirmDelete from 'components/ConfirmDialog/ConfirmDelete';
import { ax } from 'hooks/UseLoaderAxios';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);
const useTaskStyles = makeStyles(taskStyles);
const usePageStyle = makeStyles(pageStyle);

export default function Customer() {
    const classes = useStyles();
    const taskClasses = useTaskStyles()
    const pageClasses = usePageStyle()
    const [custList, setCustList] = useState([])
    const [open, setOpen] = useState(false)
    const [openSaveCustSuccess, setOpenSaveCustSuccess] = useState(false)
    const [openSaveCustErr, setOpenSaveCustErr] = useState(false)
    const [custErrorMsg, setCustErrorMsg] = useState('')
    const [totalPage, setTotalPage] = useState(0)
    const [openConfDel, setOpenConfDel] = useState(false)
    const [custSuccessMsg, setCustSuccessMsg] = useState('')
    const [idSelect, setIdSelect] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [custData, setCustData] = useState({
        custName: '',
        discount: 0
    })
    const [editModalOpen, setEditModalOpen] = useState(false)

    const genButton = (id) => {
        return [
            <IconButton className={taskClasses.tableActionButton} key={0} title="แก้ไข" onClick={() => btnEdit(id)}>
                <Edit className={`${taskClasses.tableActionButtonIcon} ${taskClasses.edit}`} />
            </IconButton>,
            <IconButton aria-label="ลบ" key={1} className={taskClasses.tableActionButton} onClick={() => btnDelete(id)}>
                <Close className={`${taskClasses.tableActionButtonIcon} ${taskClasses.close}`} />
            </IconButton>
        ]
    }

    const btnEdit = (id) => {
        setIdSelect(id)
        ax.get(`/customers/${id}`).then((res) => {
            setCustData({
                custName: res.data.custName,
                discount: res.data.discount
            })
            setEditModalOpen(true)
        }).catch((err) => {
            let data = err.response.data
            setCustErrorMsg(data.errors.message)
            setOpenSaveCustErr(true)
        })
    }
    const btnDelete = (value) => {
        setIdSelect(value)
        setOpenConfDel(true)
    }

    const confirmDel = () => {
        handleCloseConfDel()
        ax.delete(`/customers/${idSelect}`).then((res) => {
            setCustSuccessMsg('ลบข้อมูลสำเร็จ')
            setOpenSaveCustSuccess(true)
            loadCustomer(1)
        }).catch((err) => {
            console.log(err.response)
            let data = err.response.data
            setCustErrorMsg(data.errors.message)
            setOpenSaveCustErr(true)
        })
    }

    const loadCustomer = (page = 1) => {
        setCurrentPage(page)
        
        ax.get("/customers", { params: { page: page } }).then((res) => {
            setTotalPage(res.data.total_page)
            let records = res.data.records
            setCustList(makeData(records, page))
        }).catch((err) => {
            console.log(err.response)
        })
    }

    const makeData = (records, page) => {
        let dataList = []
        for (let i = 0; i < records.length; i++) {
            let item = records[i]
            let rowNum = rowNumberUtils(page, i)
            dataList.push([rowNum, item["custName"], item["discount"], genButton(item.ID)])
        }
        return dataList
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleCloseDialog = () => {
        setOpen(false)
    }

    const handleCloseSaveCustSuccess = () => {
        setOpenSaveCustSuccess(false)
    }

    const handleCloseSaveCustErr = () => {
        setOpenSaveCustErr(false)
    }

    const saveCustomerData = (data) => {
        ax.post('/customers', data).then((res) => {
            setCustSuccessMsg('บันทึกข้อมูลสำเร็จ')
            setOpenSaveCustSuccess(true)
            loadCustomer()
        }).catch((err) => {
            let data = err.response.data
            setCustErrorMsg(data.errors.message)
            setOpenSaveCustErr(true)
        })
        handleCloseDialog()
    }

    const handlePageChange = (event, value) => {
        loadCustomer(value)
    }

    const handleCloseConfDel = () => {
        setOpenConfDel(false)
    }

    const handleEditCloseModal =()=>{
        setEditModalOpen(false)
    }

    const updateCustomerSubmit =(data)=>{
        handleEditCloseModal()
        ax.put(`/customers/${idSelect}`, data).then((res)=>{
            setCustSuccessMsg('บันทึกข้อมูลสำเร็จ')
            setOpenSaveCustSuccess(true)
            loadCustomer()
        }).catch((err)=>{
            let data = err.response.data
            setCustErrorMsg(data.errors.message)
            setOpenSaveCustErr(true)
        })

    }

    useEffect(loadCustomer, [])

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <Grid container spacing={2}>
                                <GridItem xs={10} >
                                    <h4 className={"m-t-14"}>ผู้ซื้อ/คนเดินโพย</h4>
                                    <p className={classes.cardCategoryWhite}></p>
                                </GridItem>
                                <GridItem>
                                    <Button color="success" onClick={handleClickOpen}>
                                        เพิ่มข้อมูล
                                    </Button>
                                </GridItem>
                            </Grid>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["ลำดับ", "ชื่อ - นามสกุล", "ส่วนลด", "การกระทำ"]}
                                tableData={custList}
                            />

                            <Pagination count={totalPage}
                                page={currentPage}
                                className={`m-t-20 ${pageClasses.paging}`}
                                size="large" onChange={handlePageChange} />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <CreateCustomerModal open={open} handleCloseDialog={handleCloseDialog} onSubmitForm={saveCustomerData} />
            {editModalOpen ? <EditCustomerModal open={editModalOpen} handleCloseDialog={handleEditCloseModal} onSubmitForm={updateCustomerSubmit} data={custData}/> : null}

            <ToastAlert message={custSuccessMsg} open={openSaveCustSuccess} onClose={handleCloseSaveCustSuccess} />
            <ToastAlert message={custErrorMsg} open={openSaveCustErr} onClose={handleCloseSaveCustErr} severity={"error"} />
            <ConfirmDelete open={openConfDel} handleCloseDialog={handleCloseConfDel} handleCallbak={confirmDel} />
        </div>
    );
}

function CreateCustomerModal(props) {
    const { register, handleSubmit, errors } = useForm();

    const { open, handleCloseDialog, onSubmitForm } = props

    const popupSubmit = (data) => {
        onSubmitForm({
            custName: data.custName,
            discount: parseInt(data.discount)
        })
    }

    return (
        <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" fullWidth={true}
            maxWidth="sm" disableBackdropClick={true}>
            <form className="login100-form validate-form" onSubmit={handleSubmit(popupSubmit)}>
                <DialogTitle>เพิ่ม ผู้ซื้อ/คนเดินโพย</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        เพิ่มรายชื่อผู้ซื้อหรือผู้เดินโพย
                </DialogContentText>
                    <TextField margin="dense" id="custName" label="ชื่อ" fullWidth name="custName"
                        inputRef={register({ required: true })} />
                    {errors.custName && <span className="error">กรุณาระบุชื่อผู้ซื้อ</span>}
                    <br />
                    <TextField
                        margin="dense"
                        id="discount"
                        label="ส่วนลด"
                        type="number"
                        fullWidth
                        name="discount"
                        inputRef={register({ required: true })}
                    />
                    {errors.discount && <span className="error">กรุณาระบุส่วนลด</span>}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" type="submit">บันทึก</Button>
                    <Button onClick={handleCloseDialog} color="danger">ยกเลิก</Button>
                </DialogActions>
            </form>
        </Dialog >
    );
}

function EditCustomerModal(props){

    const { open, handleCloseDialog, onSubmitForm, data} = props
    const { register, handleSubmit, errors} = useForm({
        defaultValues: {
            custName: data.custName,
            discount: data.discount
        }
    });

    const popupSubmit = (data) => {
        onSubmitForm({
            custName: data.custName,
            discount: parseInt(data.discount)
        })
    }

    return (
        <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" fullWidth={true}
            maxWidth="sm" disableBackdropClick={true}>
            <form className="login100-form validate-form" onSubmit={handleSubmit(popupSubmit)}>
                <DialogTitle>แก้ไข ผู้ซื้อ/คนเดินโพย</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        แก้ไขรายชื่อผู้ซื้อหรือผู้เดินโพย
                </DialogContentText>
                    <TextField margin="dense" id="custName" label="ชื่อ" 
                        fullWidth name="custName"
                        inputRef={register({ required: true })} />
                    {errors.custName && <span className="error">กรุณาระบุชื่อผู้ซื้อ</span>}
                    <br />
                    <TextField
                        margin="dense"
                        id="discount"
                        label="ส่วนลด"
                        type="number"
                        fullWidth
                        name="discount"
                        inputRef={register({ required: true })}
                    />
                    {errors.discount && <span className="error">กรุณาระบุส่วนลด</span>}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" type="submit">บันทึก</Button>
                    <Button onClick={handleCloseDialog} color="danger">ยกเลิก</Button>
                </DialogActions>
            </form>
        </Dialog >
    );
}