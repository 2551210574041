import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import RTLNavbarLinks from "./RTLNavbarLinks.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function Header(props) {
    const location = useLocation()
    const classes = useStyles();
    function makeBrand() {
        let isFound =false;
        var name;
        props.routes.map(prop => {
            // if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
            //     name = props.rtlActive ? prop.rtlName : prop.name;
            // }

            if (location.pathname === prop.layout + prop.path) {
                name = props.rtlActive ? prop.rtlName : prop.name;
                isFound = true
            }
            
            if(!isFound && prop.routes){
                prop.routes.map(childProp =>{
                    if(location.pathname === prop.layout + childProp.path){
                        name = props.rtlActive ? childProp.rtlName : childProp.name;
                        isFound = true;
                        
                    }
                    return name
                })
            }
            return null;
        });

        return name;
    }
    const { color } = props;
    const appBarClasses = classNames({
        [" " + classes[color]]: color
    });
    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                <div className={classes.flex}>
                    {/* Here we create navbar brand, based on route name */}
                    <Button color="transparent" href="#" className={classes.title}>
                        {makeBrand()}
                    </Button>
                </div>
                <Hidden smDown implementation="css">
                    {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
                </Hidden>
                <Hidden mdUp implementation="css">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                    >
                        <Menu />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    routes: PropTypes.arrayOf(PropTypes.object)
};
