import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import { ListSubheader, MenuItem } from '@material-ui/core';
import Table from "components/Table/Table.js";
import NumberFormat from 'react-number-format';
import { ax } from 'hooks/UseLoaderAxios';

const useStyle = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}))

export const SummaryAll = () => {
  const classes = useStyle()

  // const [openConfDel, setOpenConfDel] = useState(false)
  const selectOptions = genSelectOption()
  const [tblBodyData, setTblBodyData] = useState([])

  const tableHead = ["#", "ประเภทหวย", "ตัวเลข", "จำนวนเงิน"]
  // const [lottoSelectID, setLottoSelectID] = useState(0)
  // const [errMsg, setErrMsg] = useState('')
  // const [toatAlertErr, setToatAlertErr] = useState(false)

  const loadLottoData = (lottoType) => {
    if (lottoType === 'all') {
      lottoType = null
    }

    ax.get('/lottodatas/summary-all', {
      params: {
        "lottoType": lottoType
      }
    }).then(res => {
      const datas = res.data
      let tblBodys = []

      let index = 1;
      for (let item of datas) {
        tblBodys.push([
          index++,
          item.lottoTypeDesc,
          item.lottoNumber,
          <NumberFormat value={item.amount} displayType="text" thousandSeparator={true} />
        ])
      }
      setTblBodyData(tblBodys)
    }).catch(err => {
      console.error(err.message)
    })
  }

  useEffect(() => { loadLottoData('all') }, [])
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4>สรุปรายการซื้อหวย ทั้งหมด</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="grouped-native-select">เลือกดูข้อมูล</InputLabel>
                    <Select
                      label="เลือกดูข้อมูล"
                      defaultValue="all"
                      id="grouped-native-select"
                      onChange={(event, child) => {
                        loadLottoData(event.target.value)
                      }}
                    >
                      {selectOptions.map((prop, key) => {
                        return prop
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={tableHead}
                    tableData={tblBodyData}>

                  </Table>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* <ConfirmDelete open={openConfDel} handleCloseDialog={handleCloseConfDel} handleCallbak={confirmDel} /> */}
      {/* <ToastAlert message={errMsg} open={toatAlertErr} onClose={handleToatAlertErr} severity={"error"} /> */}
    </>
  )
}

const genSelectOption = () => {
  return [
    <MenuItem value="all" key={'all'}><em>-- ดูทั้งหมด --</em></MenuItem>,
    <MenuItem value="3UP" key={'3UP'}>สามตัวบน</MenuItem>,
    <MenuItem value="3DOWN" key={'3DOWN'}>สามตัวล่าง</MenuItem>,
    <MenuItem value="3TOD" key={'3TOD'}>สามตัวโต๊ด</MenuItem>,
    <MenuItem value="3RUN" key={'3RUN'}>วิ่งสามตัว</MenuItem>,
    <ListSubheader key={'0'}>------------------------------</ListSubheader>,
    <MenuItem value="2UP" key={'2UP'}>สองตัวบน</MenuItem>,
    <MenuItem value="2RUNUP" key={'2RUNUP'}>วิ่งสองตัวบน</MenuItem>,
    <ListSubheader key={'1'}>------------------------------</ListSubheader>,
    <MenuItem value="2DOWN" key={'2DOWN'}>สองตัวล่าง</MenuItem>,
    <MenuItem value="2RUNDOWN" key={'2RUNDOWN'}>วิ่งสองตัวล่าง</MenuItem>,

  ]
}