import React, { Fragment, useEffect, useState } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import { DialogContent, InputLabel, ListItemText, makeStyles, Select } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import List from '@material-ui/core/List'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'

import IconButton from "@material-ui/core/IconButton";
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import Card from "components/Card/Card.js";
import Table from "components/Table/Table.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import { useForm } from 'react-hook-form';
import Button from 'components/CustomButtons/Button.js';
import { Autocomplete } from '@material-ui/lab';

import Close from "@material-ui/icons/Close";

import cardHeaderStyle from 'assets/jss/material-dashboard-react/components/cardHeaderStyle.js'
import taskStyles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import ConfirmDelete from 'components/ConfirmDialog/ConfirmDelete.js';

import { useRef } from 'react';
import NumberFormat from 'react-number-format'
import { useHistory, useLocation } from 'react-router-dom'

import { getLottoDate } from './KeyLottoUtils'
import { customerDropdownStore } from './StateCustomerDropdown.jsx'
import { useContext } from 'react'
import CardFooter from 'components/Card/CardFooter.js'
import ToastAlert from 'components/ToastAlert/ToastAlert.js'
import InputAutoFocus from 'utils/InputAutoFocus.js'
import { ax } from 'hooks/UseLoaderAxios'
import Checkbox from '@material-ui/core/Checkbox';

const cardHeaderStyleMake = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  keyinResult: {
    maxHeight: '633px',
    minHeight: '633px',
    overflow: 'auto'
  }
}))

const cardStyleMake = makeStyles(cardHeaderStyle)
const taskStyleMake = makeStyles(taskStyles)

export const KeyLotto = () => {
  const custDropdown = useContext(customerDropdownStore)
  const { dispatch } = custDropdown;

  const location = useLocation()
  const history = useHistory()
  const classes = cardHeaderStyleMake()
  const classCard = cardStyleMake()
  const classTask = taskStyleMake()

  let fromDate = getLottoDate()

  const [keyinDataTable, setKeyinDataTable] = useState([])
  const [openConfDel, setOpenConfDel] = useState(false)
  const [lottoSelectID, setLottoSelectID] = useState(0)
  const [errMsg, setErrMsg] = useState('')
  const [toatAlertErr, setToatAlertErr] = useState(false)
  const [custData, setCustData] = useState([])
  const [totalAmt, setTotalAmt] = useState(0)
  const [currentUserIndex, setCurrentUserIndex] = useState('')
  const [lockCustomer, setLockCustomer] = useState(false)

  const makeDataTable = (lottoDatas) => {
    let dataTable = []

    for (let i = 0; i < lottoDatas.length; i++) {
      dataTable.push([
        lottoDatas[i].lottoTypeDesc,
        lottoDatas[i].lottoNumber,
        lottoDatas[i].amount,
        lottoDatas[i].custName,
        genButtonDelete(lottoDatas[i].ID)
      ])
    }
    return dataTable
  }

  const genButtonDelete = (id) => {
    return [
      <IconButton aria-label="ลบ" key={1} className={classTask.tableActionButton} onClick={() => callBackDelete(id)}>
        <Close className={`${classTask.tableActionButtonIcon} ${classTask.close}`} />
      </IconButton>
    ]
  }

  const loadLastLotto = () => {
    ax.get('/lottodatas-lastkey', {
      params: {
        'buyDateFrom': fromDate,
        'totalRecord': 50
      }
    }).then((res) => {
      let records = res.data.datas
      setTotalAmt(res.data.totalAmount)

      if (records) {
        setKeyinDataTable(makeDataTable(records))
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const callBackDelete = (id) => {
    setLottoSelectID(id)
    setOpenConfDel(true)
  }

  const handleCloseConfDel = () => {
    setOpenConfDel(false)
  }

  const confirmDel = () => {
    ax.delete(`/lottodatas/${lottoSelectID}`).then((res) => {
      loadLastLotto()
    }).catch((err) => {
      setErrMsg(err.response.message)
      setToatAlertErr(true)
    }).finally(() => {
      handleCloseConfDel()
    })
  }

  const handleToatAlertErr = () => {
    setToatAlertErr(false)
  }

  const loadCustomer = () => {
    if (custDropdown.state && custDropdown.state.length > 0) {
      setCustData(custDropdown.state)
    } else {
      ax.get('/customers-list').then((res) => {
        setCustData(res.data)
        dispatch({ type: "add", data: res.data })
      }).catch((err) => {
        console.log(err.response)
      })
    }
  }

  const lottoReport = () => {
    history.push(location.pathname + "/report")
  }

  const currentUserCallback = (userID) => {
    if(lockCustomer){
      const index = custData.findIndex(it => it.ID === userID)
      setCurrentUserIndex(index)
    }
  }

  const lockCustomerCallback = () =>{
    let isLock = !lockCustomer
    setLockCustomer(isLock)
    console.log(isLock)

    if(isLock === false){
      setCurrentUserIndex(-1)
      console.log(currentUserIndex)
    }
  }

  useEffect(loadLastLotto, [])
  useEffect(loadCustomer, [])

  return (
    <div>
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <CustomTabs
            title="คีย์ข้อมูลหวย"
            headerColor="warning"
            tabs={[
              {
                tabName: "เลขตรง เลขโต๊ด เลขวิ่ง",
                tabContent: (
                  <KeyinNormal
                    callbackReload={loadLastLotto}
                    callbackUserSelect={currentUserCallback}
                    lockCustomerCallback={lockCustomerCallback}
                    isLockCustomer={lockCustomer}
                    custData={custData}
                  />
                )
              },
              {
                tabName: "เลขพิเศษ",
                tabContent: (
                  <KeyinSpecial callbackReload={loadLastLotto} custData={custData} currentUserIndex={currentUserIndex} />
                )
              }
            ]}
          />
        </GridItem>

        <GridItem xs={6} sm={6} md={6}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classCard.cardTitleWhite}>รายการคีย์ล่าสุด</h4>
              <p className={classCard.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <Table
                panelClass={classes.keyinResult}
                tableHeaderColor="primary"
                tableHead={["ประเภท", "ตัวเลข", "จำนวนเงิน", "ชื่อผู้ซื้อ", "ลบ"]}
                tableData={keyinDataTable}
              />
            </CardBody>
            <CardFooter>
              <GridItem xs={12} sm={12} md={12}>
                <Button color="success" onClick={lottoReport}>รายการทั้งหมด</Button>
                <span className={"sum-label"}>ยอดรวมทั้งหมด <NumberFormat value={totalAmt} thousandSeparator={true} displayType={'text'} /> บาท</span>
              </GridItem>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <ConfirmDelete open={openConfDel} handleCloseDialog={handleCloseConfDel} handleCallbak={confirmDel} />
      <ToastAlert message={errMsg} open={toatAlertErr} onClose={handleToatAlertErr} severity={"error"} />
    </div>

  )
}

function KeyinNormal(props) {

  const { callbackReload, custData, callbackUserSelect, lockCustomerCallback, isLockCustomer } = props

  const classes = cardHeaderStyleMake()
  const { register, handleSubmit, errors, setValue } = useForm()
  const lottoNumberRef = useRef(null)

  const [isOneLength, setIsOneLength] = useState(true)
  const [isTwoLength, setIsTwoLength] = useState(true)
  const [isThreeLength, setIsThreeLength] = useState(true)
  const [isAmount2Reverse, setIsAmount2Reverse] = useState(true)
  const [custNameLabel, setCustNameLabel] = useState('')
  const [toatAlertErr, setToatAlertErr] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [lockPrice, setLockPrice] = useState(false)
  const [submitStage, setSubmitStage] = useState(false)

  const keyinSubmit = (data) => {
    clearInputBeforeSend(data)
    const dataBody = {
      'custID': data.custID,
      'custName': custNameLabel,
      'lottoNumber': data.lottoNumber,
      'amountUp': (data.amountUp) ? parseInt(data.amountUp) : 0,
      'amountDown': (data.amountDown) ? parseInt(data.amountDown) : 0,
      'amountTod': (data.amountTod) ? parseInt(data.amountTod) : 0,
      'amount3Run': (data.amount3Run) ? parseInt(data.amount3Run) : 0,
      'amount2UpRun': (data.amount2UpRun) ? parseInt(data.amount2UpRun) : 0,
      'amount2DownRun': (data.amount2DownRun) ? parseInt(data.amount2DownRun) : 0,
      'amount2Reverse': (data.amount2Reverse) ? parseInt(data.amount2Reverse) : 0,
      'keyType': 'normal'
    }

    if(!submitStage){
      setSubmitStage(true)
      ax.post('/lottodatas', dataBody).then((res) => {
        setValue('lottoNumber', '')
        callbackReload()
        focusLottoNumber()
        if (!lockPrice) {
          clearAllInput();
        }
        setSubmitStage(false);
      }).catch((err) => {
        console.log(err.response)
        let response = err.response
        setErrMsg(response.data.errors.message)
        setToatAlertErr(true)
        setSubmitStage(false);
      });
    }else{
      setErrMsg('กำลังส่งข้อมูล');
      setToatAlertErr(true);
    }
  }

  const clearInputBeforeSend = (data) => {
    const value = data.lottoNumber;

    if (value.length === 1) {
      data.amountUp = null
      data.amountDown = null
      data.amountTod = null
      data.amount2Reverse = null
      setValue('amountUp', '')
      setValue('amountDown', '')
      setValue('amountTod', '')
      setValue('amount2Reverse', '')
    } else if (value.length === 2) {
      data.amount3Run = null
      data.amount2UpRun = null
      data.amount2DownRun = null
      data.amountTod = null
      setValue('amount3Run', '')
      setValue('amount2UpRun', '')
      setValue('amount2DownRun', '')
      setValue('amountTod', '')

      if (isNumberPao(value)) {
        setValue('amount2Reverse', '')
        data.amount2Reverse = null
      }
    } else if (value.length === 3) {
      data.amount3Run = null
      data.amount2UpRun = null
      data.amount2DownRun = null
      data.amount2Reverse = null
      data.amountDown = null
      setValue('amount3Run', '')
      setValue('amount2UpRun', '')
      setValue('amount2DownRun', '')
      setValue('amount2Reverse', '')
      setValue('amountDown', '')
    }
  }

  const clearAllInput = () => {
    setValue('amountUp', '')
    setValue('amountDown', '')
    setValue('amountTod', '')
    setValue('amount3Run', '')
    setValue('amount2UpRun', '')
    setValue('amount2DownRun', '')
    setValue('amount2Reverse', '')
  }

  const isNumberPao = (value) => {
    return value % 11 === 0
  }

  const handleLottoNumber = ((e) => {
    let value = e.target.value

    if (value.length === 4) {
      setValue('lottoNumber', 999)
      value = '999'
    }

    if (value.length === 0) {
      setIsOneLength(true)
      setIsTwoLength(true)
      setIsAmount2Reverse(true)
      setIsThreeLength(true)
    } else if (value.length === 1) {
      setIsOneLength(true)
      setIsTwoLength(false)
      setIsAmount2Reverse(false)
      setIsThreeLength(false)
    } else if (value.length === 2) {
      setIsOneLength(false)
      setIsTwoLength(true)
      setIsAmount2Reverse(true)
      setIsThreeLength(false)

      //check is pao number
      if (isNumberPao(value)) {
        setIsAmount2Reverse(false)
      } else {
        if (!isAmount2Reverse) {
          setIsAmount2Reverse(true)
        }
      }
    } else {
      setIsOneLength(false)
      setIsTwoLength(false)
      setIsAmount2Reverse(false)
      setIsThreeLength(true)
    }
  })

  const handleToatAlertErr = () => {
    setToatAlertErr(false)
  }

  const checkKeyDown = (e) => {
    if (e.target.name === 'lottoNumber') {
      if (!e.target.value) {
        return
      }
    }
    InputAutoFocus(e)
  }

  const focusLottoNumber = () => {
    lottoNumberRef.current.focus()
  }

  const lockPriceHandle = () => {
    setLockPrice(!lockPrice)
  }

  return (
    <Fragment>
      <form id="normal-form" onSubmit={handleSubmit(keyinSubmit)} className={classes.formControl} onKeyDown={(e) => checkKeyDown(e)}>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <FormControlLabel
              control={<Checkbox
                onChange={lockPriceHandle}
                color="primary"
                name="lockPrice" />}
              label="ล็อคราคา"
            />
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <FormControlLabel
              control={<Checkbox
                onChange={lockCustomerCallback}
                color="primary"
                checked={isLockCustomer}
                name="lockCustomer" />}
                label="จำชื่อผู้ซื้อ/เดินโพย"
            />
          </GridItem>
        </GridContainer>

        <Autocomplete
          id="combo-box-demo"
          margin="normal"
          onChange={(event, newValue) => {
            if (newValue) {
              setValue('custID', newValue.ID);
              setCustNameLabel(newValue.custName)
              callbackUserSelect(newValue.ID)
              focusLottoNumber()
            }
          }}
          ref={(e) => {
            register({ name: 'custID' }, { required: true })
            // refContainer.current = e
          }}
          options={custData}
          getOptionLabel={(option) => option.custName}
          getOptionSelected={(option) => option.ID}
          renderInput={(params) => <TextField error={errors.custID ? true : false} {...params} label="ผู้ซื้อ/คนเดินโพย" variant="outlined" margin="normal" />}

        />
        <FormHelperText>{errors.custID && <span className="error">กรุณาเลือก ผู้ซื้อ/คนเดินโพย</span>}</FormHelperText>

        <TextField error={errors.lottoNumber ? true : false} name="lottoNumber" variant="outlined" label="ตัวเลข"
          inputRef={(e) => {
            lottoNumberRef.current = e
            register(e, { required: true })
          }
          }
          fullWidth type="number" margin="normal"
          onChange={handleLottoNumber}
        />
        <FormHelperText >{errors.lottoNumber && <span className="error">กรุณากรอก ตัวเลข</span>}</FormHelperText>

        <TextField name="amountUp" variant="outlined"
          label="บน (จำนวนเงิน)"
          inputRef={register}
          fullWidth
          margin="normal"
          type="number"
          className={`${(isTwoLength || isThreeLength) ? "" : "hidden-field"}`}
        />
        <TextField name="amountDown" variant="outlined" label="ล่าง (จำนวนเงิน)"
          inputRef={register} fullWidth margin="normal" type="number"
          className={`${isTwoLength ? "" : "hidden-field"}`}
        />

        <TextField name="amount2Reverse" variant="outlined"
          label="เลขสองตัว กลับ (จำนวนเงิน)"
          inputRef={register}
          fullWidth
          margin="normal"
          type="number"
          className={`${isAmount2Reverse ? "" : "hidden-field"}`}
        />

        <TextField name="amountTod" variant="outlined" label="โต๊ด (จำนวนเงิน)"
          inputRef={register} fullWidth margin="normal"
          type="number"
          className={`${isThreeLength ? "" : "hidden-field"}`}
        />

        <TextField name="amount3Run" variant="outlined" label="วิ่ง 3 ตัว (จำนวนเงิน)"
          inputRef={register}
          fullWidth
          margin="normal"
          className={`${isOneLength === true ? "" : "hidden-field"} `}
          type="number" />

        <TextField name="amount2UpRun" variant="outlined" label="วิ่ง 2 ตัวบน (จำนวนเงิน)"
          inputRef={register}
          fullWidth
          margin="normal"
          type="number"
          className={`${isOneLength === true ? "" : "hidden-field"} `}
        />

        <TextField name="amount2DownRun" variant="outlined" label="วิ่ง 2 ตัวล่าง (จำนวนเงิน)"
          inputRef={register}
          fullWidth
          margin="normal"
          type="number"
          className={`${isOneLength === true ? "" : "hidden-field"} `}
        />

        <Button color="rose" type="submit">บันทึก</Button>
      </form>
      <ToastAlert message={errMsg} open={toatAlertErr} onClose={handleToatAlertErr} severity={"error"} />
    </Fragment>
  )
}

const KeyinSpecial = (props) => {
  const classes = cardHeaderStyleMake()

  const { register, handleSubmit, errors, setValue, unregister } = useForm()

  const [custNameLabel, setCustNameLabel] = useState('')
  const [helpModalOpen, setHelpModalOpen] = useState(false)
  const [toatAlertErr, setToatAlertErr] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const lottoNumberRef = useRef(null)
  const [specialTpyeSelect, setSpecialTpyeSelect] = useState("0")
  const [hiddenLottoNumber, setHiddenLottoNumber] = useState(false)
  const [hiddenAmountDown, setHiddenAmountDown] = useState(false)
  const { callbackReload, custData, currentUserIndex } = props
  const [submitStage, setSubmitStage] = useState(false);

  const sumitForm = (data) => {
    if (!data.amount && !data.amountDown) {
      return
    }

    if (data.specialType !== "1" && data.specialType !== "2") {
      data.amount2Reverse = 0
    }

    let body = {
      custID: data.custID,
      custName: custNameLabel,
      specialType: data.specialType,
      lottoNumber: data.lottoNumber,
      amountUp: data.amount ? parseInt(data.amount) : 0,
      amountDown: data.amountDown ? parseInt(data.amountDown) : 0,
      amount2Reverse: data.amount2Reverse ? parseInt(data.amount2Reverse) : 0,
      keyType: 'special'
    }

    if(!submitStage){
      setSubmitStage(true)
      ax.post('/lottodatas', body).then(res => {
        setValue('lottoType', '')
        setValue('lottoNumber', '')
        setValue('amount', '')
        setValue('amountDown', '')
        setValue('amount2Reverse', '')
        callbackReload()
        focusLottoNumber()
        setSubmitStage(false)
      }).catch(err => {
        console.log(err.response)
        let response = err.response
        setErrMsg(response.data.errors.message)
        setToatAlertErr(true)
        setSubmitStage(false)
      })
    } else{
        setErrMsg('กำลังส่งข้อมูล');
        setToatAlertErr(true);
    }
  }

  useEffect(() => {
    console.log(currentUserIndex)
    register({ name: "specialType" }, { required: "กรุณาเลือก ประเภทเลขพิเศษ" })
    register({ name: "lottoNumber" }, { required: true })
  }, [register])

  const handleLottoNumber = ((e) => {
    let value = e.target.value
    if (value > 1000) {
      setValue('lottoNumber', 999)
      lottoNumberRef.current.value = 999
    } else {
      setValue('lottoNumber', value)
    }
  })

  const closeHelpModalOpen = () => {
    setHelpModalOpen(false)
  }
  const handleToatAlertErr = () => {
    setToatAlertErr(false)
  }

  const checkKeyDown = (e) => {
    if (e.target.name === 'lottoNumber') {
      if (!e.target.value) {
        return
      }
    }
    InputAutoFocus(e)
  }

  const focusLottoNumber = () => {
    lottoNumberRef.current.value = ''
    setTimeout(function () {
      lottoNumberRef.current.focus()
    }, 0);
  }

  const manageComponent = (lottoType) => {

    if (lottoType === '1' || lottoType === '2') {
      setHiddenAmountDown(true)
    } else {
      setHiddenAmountDown(false)
    }

    if (lottoType === '6') {
      setHiddenLottoNumber(true)
      unregister("lottoNumber")
    } else {
      setHiddenLottoNumber(false)
      register({ name: "lottoNumber" }, { required: true })
    }
  }

  return (
    <Fragment>
      <form className={`${classes.formControl}`} onSubmit={handleSubmit(sumitForm)} onKeyDown={(e) => checkKeyDown(e)}>
        <Autocomplete
          id="combo-box-cust"
          margin="normal"
          defaultValue={custData[currentUserIndex]}
          onChange={(event, newValue) => {
            if (newValue) {
              setValue('custID', newValue.ID);
              setCustNameLabel(newValue.custName)
            }
          }}
          ref={(e) => {
            register({ name: 'custID' }, { required: true })
          }}
          options={custData}
          getOptionLabel={(option) => option.custName}
          getOptionSelected={(option) => option.ID}
          renderInput={(params) => <TextField error={errors.custID ? true : false} {...params} label="ผู้ซื้อ/คนเดินโพย" variant="outlined" margin="normal" />}
        />
        <FormHelperText>{errors.custID && <span className="error">กรุณาเลือก ผู้ซื้อ/คนเดินโพย</span>}</FormHelperText>

        <FormControl variant="outlined" fullWidth={true} margin="normal">
          <InputLabel id="ประเภทเลขพิเศษ-lable">ประเภทเลขพิเศษ</InputLabel>
          <Select labelId="ประเภทเลขพิเศษ"
            label="ประเภทเลขพิเศษ"
            defaultValue=""
            name="specialType"
            onChange={(event, newValue) => {
              setValue('specialType', newValue.props.value)
              setSpecialTpyeSelect(newValue.props.value)
              manageComponent(newValue.props.value)
            }}
            onClose={() => { focusLottoNumber() }}
            error={errors.specialType ? true : false}>
            <MenuItem value="" disabled>
              กรุณาเลือก
            </MenuItem>
            <MenuItem value={'1'}>เลข 3 ประตู</MenuItem>
            <MenuItem value={'2'}>เลข 6 ประตู</MenuItem>
            <MenuItem value={'3'}>รูดหน้า (เลข 2 ตัว)</MenuItem>
            <MenuItem value={'4'}>รูดหลัง (เลข 2 ตัว)</MenuItem>
            <MenuItem value={'5'}>รูดหน้า - รูดหลัง (เลข 2 ตัว) 19 ประตู</MenuItem>
            <MenuItem value={'6'}>รูดเป๋า 00 - 99</MenuItem>
          </Select>
          <FormHelperText>
            {errors.specialType && <span className="error">{errors['specialType'].message}</span>}
          </FormHelperText>
        </FormControl>

        <TextField error={errors.lottoNumber ? true : false} name="lottoNumber" variant="outlined" label="ตัวเลข"
          id="lottoNumber"
          inputRef={(e) => {
            lottoNumberRef.current = e
          }}
          fullWidth type="number" margin="normal"
          onChange={handleLottoNumber}
          className={(hiddenLottoNumber) ? "hidden-field" : ""}

        />
        <FormHelperText>{errors.lottoNumber && <span className="error">กรุณากรอก ตัวเลข</span>}</FormHelperText>

        <TextField name="amount" variant="outlined" label="จำนวนเงิน (บน)"
          fullWidth margin="normal"
          type="number"
          inputRef={register}
        />

        <TextField name="amountDown" variant="outlined" label="จำนวนเงิน (ล่าง)"
          fullWidth margin="normal"
          type="number"
          inputRef={register}
          className={(hiddenAmountDown) ? "hidden-field" : ""} />

        <TextField name="amount2Reverse"
          variant="outlined" label="จำนวนเงิน (เลขหมุน)"
          fullWidth margin="normal"
          className={`${specialTpyeSelect === "1" || specialTpyeSelect === "2" ? "" : "hidden-field"} `}
          type="number"
          inputRef={register} />

        <Button color="rose" type="submit">บันทึก</Button>
      </form>
      <KeyinSpecialHelpModal isOpen={helpModalOpen} handleCloseDialog={closeHelpModalOpen} classes={classes}></KeyinSpecialHelpModal>
      <ToastAlert message={errMsg} open={toatAlertErr} onClose={handleToatAlertErr} severity={"error"} />
      <br />
      <br />
      <br />
      <Button onClick={() => {
        setHelpModalOpen(true)
      }}>วิธีคีย์เลขพิเศษ</Button>
    </Fragment>
  )
}

const KeyinSpecialHelpModal = (props) => {

  const { isOpen, handleCloseDialog, classes } = props
  return (
    <Dialog open={isOpen} onClose={handleCloseDialog} aria-labelledby="วิธีคีย์ข้อมูล" fullWidth={true}
      maxWidth="sm">
      <DialogTitle>
        วิธีคีย์เลขพิเศษ
      </DialogTitle>

      <DialogContent>
        <List className={classes.root}>
          <ListItem alignItems="flex-start">
            <ListItemText primary="เลข 3 ประตู"
              secondary={
                <React.Fragment>
                  {"เช่น คีย์เลข 477 จะได้เลขชุด 477 747 774"}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider component="li" />

          <ListItem alignItems="flex-start">
            <ListItemText primary="เลข 6 ประตู"
              secondary={
                <React.Fragment>
                  {"เช่น คีย์เลข 123 (เลขที่ไม่ซ้ำกัน) จะได้เลขชุด 123 132 213 231 312 231"}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider component="li" />

          <ListItem alignItems="flex-start">
            <ListItemText primary="เลขรูดหน้า"
              secondary={
                <React.Fragment>
                  {"เช่น คีย์เลข 2 จะได้เลขชุด 20 21 22 23 24 25 26 27 28 29"}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider component="li" />

          <ListItem alignItems="flex-start">
            <ListItemText primary="เลขรูดหลัง"
              secondary={
                <React.Fragment>
                  {"เช่น คีย์เลข 2 จะได้เลขชุด 02 12 22 32 42 52 62 72 82 92"}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider component="li" />

          <ListItem alignItems="flex-start">
            <ListItemText primary="เลขรูดหน้า - หลัง (19 ประตู)"
              secondary={
                <React.Fragment>
                  {"เช่น คีย์เลข 2 จะได้เลขชุด 20 21 22 23 24 25 26 27 28 29 02 12 22 32 42 52 62 72 82 92"}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider component="li" />

        </List>
      </DialogContent>
    </Dialog>
  )
}
