import Dashboard from "@material-ui/icons/Dashboard";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { AttachMoney } from "@material-ui/icons";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

const menu = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: Dashboard,
        layout: "/user"
    },
    {
        path: "/keyin",
        name: 'คีย์ข้อมูลหวย',
        icon: AssignmentTurnedInIcon,
        layout: "/user",
    },
    {
        path: "/customers/list",
        name: "ผู้ซื้อ/คนเดินโพย",
        icon: PeopleIcon,
        layout: "/user"
    },
    {
        path: "/lottoConfig",
        name: "ตั้งค่าตัดเก็บรายตัว",
        icon: SettingsIcon,
        layout: "/user"
    },
    {
        path: "/summary",
        name: "สรุปยอดหวย",
        icon: ReceiptIcon,
        layout: "/user",
        group: [
            {
                path: "/summary/runnumber",
                name: "สรุปเลขวิ่ง",
                layout: "/user"
            },
            {
                path: "/summary/twonumber",
                name: "สรุปเลขสองตัว",
                layout: "/user"
            },
            {
                path: "/summary/threenumber",
                name: "สรุปเลขสามตัว",
                layout: "/user"
            },
            {
                path: "/summary/all",
                name: "สรุปเลขทั้งหมด",
                layout: "/user"
            },
            {
              path: "/summary/customerBuy",
              name: "สรุปยอดซื้อรายคน",
              layout: "/user"
            }
        ]
    },
    {
      path: "/checkaward",
      name: "ตรวจรางวัล",
      icon: AttachMoney,
      layout: "/user"
    },
    {
      path: "/profile/user",
      name: "ข้อมูลผู้ใช้งาน",
      icon: PersonOutlineIcon,
      layout: "/user"
    }
]

export default menu;