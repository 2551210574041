import { format } from 'date-fns'

const getLottoDate = () => {
    let nowDate = new Date()
    let dm = 1
    let strDate = format(nowDate, 'dd')

    let dateCompare = parseInt(strDate)
    if (dateCompare > 15 && dateCompare <= 31) {
        dm = 16
    }
    nowDate.setDate(dm)
    return format(nowDate, 'yyyy-MM-dd')
}

export {
    getLottoDate
}