import React from "react"
import NumberFormat from "react-number-format"

const ConvertDataToTableBody= (datas) =>{
    
    let bodys = []
    let totalAmt = 0

    if (!datas) {
        return {
          'bodys': bodys,
          'totalAmount': totalAmt
        }
    }

    for (let i = 0; i < datas.length; i++) {
        bodys.push([
            i+1,
            datas[i].lottoNumber,
            <NumberFormat value={datas[i].amount} displayType={'text'} thousandSeparator={true}/>
        ])
        totalAmt += datas[i].amount
    }

    let result = {
        'bodys': bodys,
        'totalAmount': totalAmt
    }
    return result
}

const ConvertDataToTableMainPage = (datas) =>{
    if (!datas || datas.length === 0) {
        return [];
    }

    let result = [];
    for (let i = 0; i < datas.length; i++) {
        result.push([i+1,datas[i].lottoNumber, 
          <NumberFormat value={datas[i].amount} displayType={'text'} thousandSeparator={true}/>
        ]);
    }
    return result;
}

export {
    ConvertDataToTableBody,
    ConvertDataToTableMainPage
}