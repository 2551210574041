import React, { useEffect, useState } from 'react'
import {useLocation } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Button from "components/CustomButtons/Button"
import Table from "components/Table/Table.js";
import { ConvertDataToTableMainPage } from './SummaryNumberUtils';
import { ax } from 'hooks/UseLoaderAxios';
import { openInNewTab } from 'utils/PageUtils';


export const ThreeNumber = () => {
  const location = useLocation()

  const tableHead = ["#", "ตัวเลข", "จำนวนเงิน"];
  const [data3Up, setData3Up] = useState([])
  // const [data3Down, setData3Down] = useState([])
  const [data3Tod, setData3Tod] = useState([])

  const loadSumData = () => {
    ax.get('/dashboard/user/sum-number-three').then(res => {
      const datas = res.data

      let threeUpResult = ConvertDataToTableMainPage(datas.number3Up.items)
      threeUpResult.push(
        [<Typography variant="button" gutterBottom>รวม</Typography>,
        "",
        <NumberFormat value={datas.number3Up.totalAmount} displayType={'text'}
          thousandSeparator={true}
          renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />
        ])
      setData3Up(threeUpResult)

      // let threeDownResult = ConvertDataToTableMainPage(datas.number3Down.items)
      // threeDownResult.push(
      //   [<Typography variant="button" gutterBottom>รวม</Typography>,
      //   "",
      //   <NumberFormat value={datas.number3Down.totalAmount} displayType={'text'}
      //     thousandSeparator={true}
      //     renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />
      //   ])
      // setData3Down(threeDownResult)

      let threeTodResult = ConvertDataToTableMainPage(datas.number3Tod.items)
      threeTodResult.push(
        [<Typography variant="button" gutterBottom>รวม</Typography>,
        "",
        <NumberFormat value={datas.number3Tod.totalAmount} displayType={'text'}
          thousandSeparator={true}
          renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />
        ])
      setData3Tod(threeTodResult)
    }).catch(err => {
      console.error(err)
    })
  }

  const btnSummary = (page) => {
    let pageUrl = ''
    switch (page) {
      case 'keep':
        pageUrl = location.pathname + "/keep"
        break
      case 'export':
        pageUrl = location.pathname + "/export"
        break
      default:
        pageUrl = location.pathname
    }

    openInNewTab(pageUrl)
  }

  useEffect(loadSumData, [])
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>

          <Card>
            <CardHeader color="primary">
              <h4>สรุป เลขสามตัว</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
                  <Button color="success" size="lg" className={"m-r-20"} onClick={() => { btnSummary('keep') }}>
                    สรุปส่วนตัดเก็บ
                                    </Button>
                  <Button color="danger" size="lg" onClick={() => { btnSummary('export') }}>
                    สรุปส่วนตัดส่ง
                                    </Button>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <Card>
                    <CardHeader color="success" className="text-center">
                      <h6>สามตัวบน</h6>
                    </CardHeader>
                    <CardBody>
                      <Table
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={data3Up}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem>
                {/* <GridItem xs={4} sm={4} md={4}>
                  <Card>
                    <CardHeader color="danger" className="text-center">
                      <h6>สามตัวล่าง</h6>
                    </CardHeader>
                    <CardBody>
                      <Table
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={data3Down}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem> */}
                <GridItem xs={6} sm={6} md={6}>
                  <Card>
                    <CardHeader color="rose" className="text-center">
                      <h6>สามตัวโต๊ด</h6>
                    </CardHeader>
                    <CardBody>
                      <Table
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={data3Tod}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    </>
  )
}
