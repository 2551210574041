import React, { useEffect, useRef, useState } from 'react'

import NumberFormat from 'react-number-format'
import { TextField, Typography } from '@material-ui/core'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import Button from "components/CustomButtons/Button"
import Table from "components/Table/Table.js";
import { ConvertDataToTableBody } from './SummaryNumberUtils'
import { ax } from 'hooks/UseLoaderAxios'
import { useHistory } from 'react-router-dom'
import ConfirmInfo from 'components/ConfirmDialog/ConfirmInfo'
import Danger from 'components/Typography/Danger'
import ToastAlert from 'components/ToastAlert/ToastAlert'
import { Autocomplete } from '@material-ui/lab'

export const TwoNumberExport = () => {
    const history = useHistory()
    const tableHead = ["#", "ตัวเลข", "จำนวนเงิน"];
    const [number2UpDataBody, setNumber2UpDataBody] = useState([])
    const [number2DownDataBody, setNumber2DownDataBody] = useState([])
    const [open, setOpen] = useState(false)
    const [exportType, setExportType] = useState('')
    const [toatAlertErr, setToatAlertErr] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [custData, setCustData] = useState([])
    const [custSelect, setCustSelect] = useState('')
    const autocompleteUp = useRef(null)
    const autocompleteDown = useRef(null)

    const [number2UpDataStore, setNumber2UpDataStore] = useState([])
    const [number2DownDataStore, setNumber2DownDataStore] = useState([])

    const loadSumData = () => {
        ax.post('/lottodatas/summary', {
            "lottoType": "LOTTO_TWO_NUMBER",
            "riskType": "EXPORT"
        }).then(res => {
            const datas = res.data
            setNumber2UpDataStore(datas.number2Up)
            let number2UpResult = ConvertDataToTableBody(datas.number2Up)
            let number2UpDatas = number2UpResult.bodys
            number2UpDatas.push(
                [<Typography variant="button" gutterBottom>รวม</Typography>,
                    "",
                <NumberFormat value={number2UpResult.totalAmount}
                    displayType={'text'} thousandSeparator={true}
                    renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />])
            setNumber2UpDataBody(number2UpDatas)

            setNumber2DownDataStore(datas.number2Down)
            let number2DownResult = ConvertDataToTableBody(datas.number2Down)
            let number2DownDatas = number2DownResult.bodys
            number2DownDatas.push(
                [<Typography variant="button" gutterBottom>รวม</Typography>,
                    "",
                <NumberFormat value={number2DownResult.totalAmount}
                    displayType={'text'} thousandSeparator={true}
                    renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />])
            setNumber2DownDataBody(number2DownDatas)
        }).catch(err => {
            console.error(err.response)
        })
    }

    const exportExcel = () => {
        ax.post('/lottodatas/summary-export', {
            "lottoType": "LOTTO_TWO_NUMBER",
            "riskType": "EXPORT"
        }, {
            responseType: 'blob'
        }).then(res => {
            const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = downloadUrl
            link.setAttribute('download', 'สรุปยอดตัดส่งเลขสองตัว.xlsx')
            document.body.appendChild(link)
            link.click()
            link.remove()
        }).catch(err => {
            console.error(err.response)
        })
    }
    const loadCustomer = () => {
        ax.get('/customers-list').then((res) => {
            setCustData(res.data)
        }).catch((err) => {
            console.log(err.response)
        })
    }

    useEffect(loadSumData, [])
    useEffect(loadCustomer, [])

    const createLottoData = () => {
        const dataBody = {
            "lottoType": "",
            "custName": "",
            "custID": "",
            "items": []
        }
        if (exportType === 'up') {
            const items = number2UpDataStore.map(i => {
                    return { 'lottoNumber': i.lottoNumber, "amount": -1 * i.amount}
                })
            dataBody.lottoType = "2UP"
            dataBody.items = items
        } else {
            const items = number2DownDataStore.map(i => {
                    return { 'lottoNumber': i.lottoNumber, "amount": -1 * i.amount }
                })
            dataBody.lottoType = "2DOWN"
            dataBody.items = items
        }

        if (!custSelect) {
            setErrMsg("กรุณาเลือกคนเดินโพย")
            setToatAlertErr(true)
            return
        }

        dataBody.custID = custSelect.ID
        dataBody.custName = custSelect.custName

        if (dataBody.items.length > 0) {
            ax.post('/lottodatas/list', dataBody).then((res) => {
                setCustSelect(null);
                clearAutoSelect(dataBody.lottoType);
                clearDataTable();
            }).catch((err) => {
                console.log(err.response)
                let response = err.response
                setErrMsg(response.data.errors.message)
                setToatAlertErr(true)
            })
        }
    }

    const clearDataTable = () => {
        if (exportType === 'up') {
            setNumber2UpDataBody([])
        } else {
            setNumber2DownDataBody([])
        }
    }

    const clearAutoSelect = (lottoType) => {
        if (lottoType === "2UP") {
            autocompleteUp.current.childNodes[0].childNodes[1].childNodes[1].childNodes[0].click()
        } else {
            autocompleteDown.current.childNodes[0].childNodes[1].childNodes[1].childNodes[0].click()
        }
    }

    return (
        <>
            <GridContainer id="divToPrint">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="danger">
                            <h4>สรุปยอดตัดส่ง เลขสองตัว</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }} className="print-hide">
                                    <Button color="rose" size="lg" className={'m-r-20 card-print'} onClick={() => { loadSumData() }}>โหลดข้อมูล</Button>
                                    <Button color="success" size="lg" className={'m-r-20 card-print'} onClick={() => { window.print() }}>พิมพ์</Button>
                                    <Button color="primary" size="lg" className={"m-r-20 card-print"} onClick={() => { exportExcel() }} >
                                        พิมพ์ Excel
                                    </Button>
                                    <Button color="info" size="lg" className={"m-r-20 card-print"} onClick={() => { history.push("/user/summary/twonumber") }}>
                                        กลับ
                                    </Button>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6} className="print-np">
                                    <Card>
                                        <CardHeader color="success" className="text-center">
                                            <h6>สองตัวบน</h6>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridItem xs={3} sm={3} md={3} className="print-hide">
                                                    <Button color="danger"
                                                        onClick={() => {
                                                            setExportType('up')
                                                            setOpen(true)
                                                        }}>ตัดยอดเงิน</Button>
                                                </GridItem>
                                                <GridItem xs={9} sm={9} md={9} className="print-hide">
                                                    <Autocomplete
                                                        ref={autocompleteUp}
                                                        id="combo-box-demo"
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                setCustSelect(newValue);
                                                            }
                                                        }}
                                                        options={custData}
                                                        getOptionLabel={(option) => option.custName}
                                                        getOptionSelected={(option) => option.ID}
                                                        renderInput={(params) => <TextField {...params} label="ผู้ซื้อ/คนเดินโพย" variant="outlined" />}
                                                    />
                                                </GridItem>
                                            </GridContainer>

                                            <Table
                                                panelClass="print-tbl"
                                                tableHeaderColor="primary"
                                                tableHead={tableHead}
                                                tableData={number2UpDataBody}>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6} className="print-np">
                                    <Card>
                                        <CardHeader color="danger" className="text-center">
                                            <h6>สองตัวล่าง</h6>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridItem xs={3} sm={3} md={3} className="print-hide">
                                                    <Button color="danger"
                                                        onClick={() => {
                                                            setExportType('down')
                                                            setOpen(true)
                                                        }}>ตัดยอดเงิน</Button>
                                                </GridItem>

                                                <GridItem xs={9} sm={9} md={9} className="print-hide">
                                                    <Autocomplete
                                                        id="combo-box-demo2"
                                                        ref={autocompleteDown}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                setCustSelect(newValue);
                                                            }
                                                        }}
                                                        options={custData}
                                                        getOptionLabel={(option) => option.custName}
                                                        getOptionSelected={(option) => option.ID}
                                                        renderInput={(params) => <TextField {...params} label="ผู้ซื้อ/คนเดินโพย" variant="outlined" />}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            <Table
                                                panelClass="print-tbl"
                                                tableHeaderColor="primary"
                                                tableHead={tableHead}
                                                tableData={number2DownDataBody}>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>

                        </CardBody>
                    </Card>
                </GridItem>
                <ConfirmInfo open={open} handleCloseDialog={() => { setOpen(false) }}
                    handleCallbak={createLottoData}
                    title="ยืนยันตัดยอดส่งออก">
                    <Danger>ระบบจะทำการบันทึกข้อมูลยอดส่งออก โดยนำยอดเงินไปลบออกจากยอดที่คีย์มาในระบบ</Danger>
                </ConfirmInfo>
                <ToastAlert message={errMsg} open={toatAlertErr} onClose={() => { setToatAlertErr(false) }} severity={"error"} />
            </GridContainer>
        </>
    )
}
