import DashboardPage from "views/Dashboard/Dashboard";
import Customer from "views/Customer/Customer";
import { KeyLotto } from "views/KeyLotto/KeyLotto";
import { KeyLottoReport } from "views/KeyLotto/KeyLottoReport";
import { StateCustomerDropdown } from "views/KeyLotto/StateCustomerDropdown";
import { LottoConfig } from "views/LottoConfig/LottoConfig";
import { RunNumber } from "views/Summary/RunNumber";
import { RunNumberKeep } from "views/Summary/RunNumberKeep";
import { RunNumberExport } from "views/Summary/RunNumberExport";
import { TwoNumber } from "views/Summary/TwoNumber";
import { TwoNumberKeep } from "views/Summary/TwoNumberKeep";
import { TwoNumberExport } from "views/Summary/TwoNumberExport";
import { ThreeNumber } from "views/Summary/ThreeNumber";
import { ThreeNumberKeep } from "views/Summary/ThreeNumberKeep";
import { ThreeNumberExport } from "views/Summary/ThreeNumberExport";
import { SummaryAll } from "views/Summary/SummaryAll";
import { CheckAward } from "views/CheckAward/CheckAward"
import { ChangePassword } from "views/UserProfile/ChangePassword";
import { CustomerBuy } from "views/Summary/CustomerBuy";
import UserProfile from "views/UserProfile/UserProfile";

const pageRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardPage,
    layout: "/user"
  },
  {
    path: "/keyin",
    name: 'คีย์ข้อมูลหวย',
    component: KeyLotto,
    layout: "/user",
    context: StateCustomerDropdown,
    routes: [
      {
        path: "/keyin/report",
        name: "รายงาน คีย์ข้อมูลหวย",
        component: KeyLottoReport
      },
    ]
  },
  {
    path: "/customers/list",
    name: "ผู้ซื้อ/คนเดินโพย",
    component: Customer,
    layout: "/user"
  },
  {
    path: "/lottoConfig",
    name: "ตั้งค่าตัดเก็บรายตัว",
    component: LottoConfig,
    layout: "/user"
  },
  {
    path: "/summary/runnumber",
    name: "สรุป เลขวิ่ง",
    component: RunNumber,
    layout: "/user",
    routes: [
      {
        path: "/summary/runnumber/keep",
        name: "สรุป เลขวิ่ง / สรุปยอดตัดเก็บ เลขวิ่ง",
        component: RunNumberKeep
      },
      {
        path: "/summary/runnumber/export",
        name: "สรุป เลขวิ่ง / สรุปยอดตัดส่ง เลขวิ่ง",
        component: RunNumberExport
      },
    ]
  },
  {
    path: "/summary/twonumber",
    name: "สรุป เลขสองตัว",
    component: TwoNumber,
    layout: "/user",
    routes: [
      {
        path: "/summary/twonumber/keep",
        name: "สรุป เลขสองตัว / สรุปยอดตัดเก็บ เลขสองตัว",
        component: TwoNumberKeep
      },
      {
        path: "/summary/twonumber/export",
        name: "สรุป เลขสองตัว / สรุปยอดตัดส่ง เลขสองตัว",
        component: TwoNumberExport
      }
    ]
  },
  {
    path: "/summary/threenumber",
    name: "สรุป เลขสามตัว",
    component: ThreeNumber,
    layout: "/user",
    routes: [
      {
        path: "/summary/threenumber/keep",
        name: "สรุป เลขสามตัว / สรุปยอดตัดเก็บ เลขสามตัว",
        component: ThreeNumberKeep
      },
      {
        path: "/summary/threenumber/export",
        name: "สรุป เลขสามตัว / สรุปยอดตัดส่ง เลขสามตัว",
        component: ThreeNumberExport
      }
    ]
  },
  {
    path: "/summary/all",
    name: "สรุปรายการซื้อหวย ทั้งหมด",
    component: SummaryAll,
    layout: "/user"
  },
  {
    path: "/summary/customerBuy",
    name: "สรุปยอดซื้อรายคน",
    component: CustomerBuy,
    layout: "/user"
  },
  {
    path: "/checkaward",
    name: "ตรวจรางวัล",
    component: CheckAward,
    layout: "/user"
  },
  {
    path: "/profile/changePassword",
    component: ChangePassword,
    name: "เปลี่ยนรหัสผ่าน",
    layout: "/user",
  },
  {
    path: "/profile/user",
    component: UserProfile,
    name: "ข้อมูลผู้ใช้งาน",
    layout: "/user",
  }

]

export default pageRoutes;