import React, { useEffect, useState } from 'react'

import NumberFormat from 'react-number-format'
import { Typography } from '@material-ui/core'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import Button from "components/CustomButtons/Button"
import Table from "components/Table/Table.js";
import { ConvertDataToTableBody } from './SummaryNumberUtils'
import { ax } from 'hooks/UseLoaderAxios'
import { useHistory } from 'react-router-dom'

export const TwoNumberKeep = () => {
  const history = useHistory()
  const tableHead = ["#", "ตัวเลข", "จำนวนเงิน"];
  const [number2UpDataBody, setNumber2UpDataBody] = useState([])
  const [number2DownDataBody, setNumber2DownDataBody] = useState([])

  const loadSumData = () => {
    ax.post('/lottodatas/summary', {
      "lottoType": "LOTTO_TWO_NUMBER",
      "riskType": "KEEP"
    }).then(res => {

      const datas = res.data

      let number2UpResult = ConvertDataToTableBody(datas.number2Up)
      let number2UpDatas = number2UpResult.bodys
      number2UpDatas.push(
        [<Typography variant="button" gutterBottom>รวม</Typography>,
          "",
        <NumberFormat value={number2UpResult.totalAmount}
          displayType={'text'} thousandSeparator={true}
          renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />])
      setNumber2UpDataBody(number2UpDatas)

      let number2DownResult = ConvertDataToTableBody(datas.number2Down)
      let number2DownDatas = number2DownResult.bodys
      number2DownDatas.push(
        [<Typography variant="button" gutterBottom>รวม</Typography>,
          "",
        <NumberFormat value={number2DownResult.totalAmount}
          displayType={'text'} thousandSeparator={true}
          renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />])
      setNumber2DownDataBody(number2DownDatas)
    }).catch(err => {
      console.error(err.resposne)
    })
  }

  const exportExcel = () => {
    ax.post('/lottodatas/summary-export', {
      "lottoType": "LOTTO_TWO_NUMBER",
      "riskType": "KEEP"
    }, {
      responseType: 'blob'
    }).then(res => {
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', 'สรุปยอดตัดเก็บเลขสองตัว.xlsx')
      document.body.appendChild(link)
      link.click()
      link.remove()
    }).catch(err => {
      console.error(err.response)
    })
  }

  useEffect(loadSumData, [])

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4>สรุปยอดตัดเก็บ เลขสองตัว</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }} className="print-hide">
                  <Button color="success" size="lg" className={'m-r-20 card-print'} onClick={() => { window.print() }}>พิมพ์</Button>
                  <Button color="primary" size="lg" className={"m-r-20 card-print"} onClick={() => { exportExcel() }}>
                    พิมพ์ Excel
                  </Button>
                  <Button color="info" size="lg" className={"m-r-20 card-print"} onClick={() => { history.push("/user/summary/twonumber") }}>
                    กลับ
                  </Button>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} className="print-np">
                  <Card>
                    <CardHeader color="success" className="text-center">
                      <h6>สองตัวบน</h6>
                    </CardHeader>
                    <CardBody>
                      <Table
                        panelClass="print-tbl"
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={number2UpDataBody}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} className="print-np">
                  <Card>
                    <CardHeader color="danger" className="text-center">
                      <h6>สองตัวล่าง</h6>
                    </CardHeader>
                    <CardBody>
                      <Table
                        panelClass="print-tbl"
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={number2DownDataBody}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  )
}