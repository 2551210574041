import React from "react"
import { useEffect } from "react"

import { ax } from 'hooks/UseLoaderAxios';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card.js";
import { FormControl, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CardBody from "components/Card/CardBody";
import { useState } from "react";
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab';
import Table from "components/Table/Table.js";
import { from, zip } from "rxjs";
import { map, reduce, toArray } from 'rxjs/operators';
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
}))

export const CustomerBuy = () => {
  const classes = useStyles();
  const [customerList, setCustomerList] = useState([])
  const [tableData, setTableData] = useState([])

  const loadCustomerDropdown = () => {
    ax.get('/customers-list').then(res => {
      if (res.data) {
        setCustomerList(res.data)
      }

    }).catch((err) => {
      console.error(err);
    })
  }

  const loadLottoDataCustomerSummary = (customerID) => {
    ax.get(`/lottodatas/customer/summary?customerID=${customerID}`).then(res => {
      const datas = convertToDataTableBody(res.data)
      setTableData(datas)
    }).catch(err => {
      console.error(err.response)
    })
  }

  useEffect(loadCustomerDropdown, [])

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <Grid container spacing={2}>
                <GridItem xs={10} >
                  <h4 className={"m-t-14"}>สรุปยอดซื้อรายคน</h4>
                  <p className={classes.cardCategoryWhite}></p>
                </GridItem>
              </Grid>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <Autocomplete
                      id="combo-box-demo"
                      margin="normal"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          loadLottoDataCustomerSummary(newValue.ID)
                        }
                      }}
                      options={customerList}
                      getOptionLabel={(option) => option.custName}
                      getOptionSelected={(option) => option.ID}
                      renderInput={(params) => <TextField {...params} label="ชื่อผู้ซื้อ" variant="outlined" margin="normal" />}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["#","ชื่อผู้ซื้อ","ประเภท", "ตัวเลข", "จำนวนเงิน"]}
                    tableData={tableData}
                  >
                  </Table>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  )
}

const convertToDataTableBody = (datas) => {
  if (!datas) {
    return []
  }
  let result = []
  const dataStream = from(datas).pipe(map((x, index) => {
    return [
      index+1,
      x.custName,
      x.lottoTypeDesc,
      x.lottoNumber,
      x.lottoAmount
    ]
  }), toArray())
  const sumAmount = from(datas).pipe(map((item)=>item.lottoAmount),reduce((acc, value)=> acc +value))
 
  zip(dataStream, sumAmount).subscribe(([dataList, totalAmount])=>{
    result = dataList
    result.push([
      <Typography variant="button" gutterBottom>รวม</Typography>,
      "","", "",
      <NumberFormat value={totalAmount} displayType={'text'}
      thousandSeparator={true}
      renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>}/>
    ])
  },error=>console.error(error)).unsubscribe()
  return result
}