import React from 'react'
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

import GridContainer from "components/Grid/GridContainer";
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { Fragment } from 'react';
import { FormControl, MenuItem, makeStyles, TextField } from '@material-ui/core';
import CustomSelect from 'components/CustomSelect/CustomSelect';

import Table from "components/Table/Table.js";
import Button from 'components/CustomButtons/Button.js';
import { useState } from 'react';
import { from } from 'rxjs';
import { map, toArray } from 'rxjs/operators'
import { ax } from 'hooks/UseLoaderAxios';

const cardHeaderStyleMake = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}))

export const CheckAward = () => {
  const [btnExpDisable, setBtnExpDisable] = useState(true)
  const classes = cardHeaderStyleMake()
  const { register, handleSubmit, errors, control } = useForm()

  const [tableDatas, setTableDatas] = useState([])
  const searchCheckAward = (data) => {
    setBtnExpDisable(false)
    ax.get('/lottodatas/check-award', {
      params: {
        lottoType: data.lottoType,
        lottoNumber: data.lottoNumber
      }
    }).then((rest) => {

      let dataTableList = convertToDataTable(rest.data)
      setTableDatas(dataTableList)
    }).catch((err) => {
      console.log(err)
    })
  }

  const exportExcel = () => {
    ax.post('/lottodatas/check-award-export', {
      "lottoType": control.getValues().lottoType,
      "lottoNumber": control.getValues().lottoNumber
    }, {
      responseType: 'blob'
    }).then(res => {
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', 'ตรวจรางวัล.xlsx')
      document.body.appendChild(link)
      link.click()
      link.remove()
    }).catch(err => {
      console.error(err.response)
    })
  }

  useEffect(() => {
    register({ name: "lottoType" }, { required: "กรุณาเลือก ประเภทหวย" })
  }, [register])
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4>ตรวจรางวัล</h4>
            </CardHeader>
            <CardBody>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Fragment>
                    <form className={`${classes.formControl}`} onSubmit={handleSubmit(searchCheckAward)}>
                      <FormControl variant="outlined" fullWidth={true} margin="normal">
                        <CustomSelect name="lottoType" label="ประเภทหวย"
                          errors={errors}
                          error={Boolean(errors.lottoType)}
                          control={control} defaultValue="">
                          {lottoTypeItems()}
                        </CustomSelect>
                      </FormControl>
                      <TextField error={errors.lottoNumber ? true : false} name="lottoNumber" variant="outlined" label="ตัวเลข" inputRef={register({ required: true })}
                        fullWidth type="number" margin="normal" />
                      <Button color="primary" type="button" disabled={btnExpDisable} onClick={() => { exportExcel() }}>พิมพ์ Excel</Button>&nbsp;&nbsp;
                      <Button color="rose" type="submit">ค้นหา</Button>
                    </form>
                  </Fragment>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["ประเภท", "ตัวเลข", "ผู้ซื้อ", "จำนวนเงิน", "ส่วนลด", "ยอดที่จ่าย"]}
                    tableData={tableDatas} />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  )
}

function lottoTypeItems() {
  return [
    <MenuItem value="" key={0} disabled>
      กรุณาเลือก
    </MenuItem>,
    <MenuItem value="2UP" key={1}>สองตัวบน</MenuItem>,
    <MenuItem value="2DOWN" key={2}>สองตัวล่าง</MenuItem>,
    <MenuItem value="2RUNUP" key={3}>วิ่งสองตัวบน</MenuItem>,
    <MenuItem value="2RUNDOWN" key={4}>วิ่งสองตัวล่าง</MenuItem>,
    <MenuItem value="3UP" key={5}>สามตัวบน</MenuItem>,
    <MenuItem value="3DOWN" key={6}>สามตัวล่าง</MenuItem>,
    <MenuItem value="3RUN" key={7}>วิ่งสามตัว</MenuItem>,
    <MenuItem value="3TOD" key={8}>สามตัวโต๊ด</MenuItem>
  ]
}

const convertToDataTable = (datas) => {
  let result = []
  if (!datas) {
    return result
  }
  const sources = from(datas);
  const subscribe = sources.pipe(map(data => {
    const item = [
      data['lottoTypeDesc'],
      data['lottoNumber'],
      data['custName'],
      data['lottoAmount'],
      data['discount'] + " %",
      data['totalAmount']
    ]
    return item
  }), toArray())
  subscribe.subscribe(item => result = item)
  return result
}