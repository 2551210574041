import React from "react"
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { Table, TableBody, TableCell, MenuItem, FormControl } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";

import Checkbox from '@material-ui/core/Checkbox';

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button"
import ToastAlert from 'components/ToastAlert/ToastAlert.js'
import ConfirmDelete from "components/ConfirmDialog/ConfirmDelete";
import { useContext } from "react";
import { customerDropdownStore } from './StateCustomerDropdown.jsx'
import { Autocomplete } from "@material-ui/lab";
import { ax } from "hooks/UseLoaderAxios.jsx";
import { useEffect } from "react";
import { useForm } from 'react-hook-form';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import Moment from "react-moment";

export const KeyLottoReport = (props) => {
  const customerDropDownStoreStage = useContext(customerDropdownStore)
  const tableHeader = ["ประเภทหวย", "ตัวเลข", "จำนวนเงิน", "วัน - เวลา", "ผู้ซื้อ"]
  const history = useHistory()

  const [toatAlertErr, setToatAlertErr] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [reportDatas, setReportDatas] = useState([])
  const [numSelected, setNumSelected] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [confDelOpen, setConfDelOpen] = useState(false)

  const [customerDropdown, setCustomerDropdown] = useState([])
  const { register, handleSubmit, errors, control, setValue, getValues } = useForm()

  const loadReport = (data) => {
    ax.get('/lottodatas-list', {
      params: {
        "custID": data.custID,
        "lottoType": data.lottoType,
        "lottoNumber": data.lottoNumber
      }
    }).then((res) => {
      if (res.data != null) {
        setReportDatas(res.data)
        setRowCount(res.data.length)
      } else {
        setReportDatas([])
        setRowCount(0)
      }
    }).catch((err) => {
      if (err.response) {
        setErrMsg(err.response.data.message)
        setToatAlertErr(true)
      }
    })
  }

  const handleToatAlertErr = () => {
    setToatAlertErr(false)
  }

  const handleClick = (event, id) => {
    const selectedIndex = numSelected.indexOf(id)
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(numSelected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(numSelected.slice(1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(numSelected.slice(0, selectedIndex),
        numSelected.slice(selectedIndex + 1))
    }

    setNumSelected(newSelected)
  }

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = reportDatas.map((v) => v.ID)
      setNumSelected(newSelecteds)
      return
    }

    setNumSelected([])
  }

  const isSelected = (id) => numSelected.indexOf(id) !== -1;

  const handleConfDelClose = () => {
    setConfDelOpen(false)
  }

  const confDelClick = () => {
    handleConfDelClose()
    if (numSelected.length === 0) {
      return
    }
    ax.post('/lottodatas/deletes', {
      "ids": numSelected
    }).then((res) => {
      loadReport(getValues())
    }).catch((err) => {
      setErrMsg(err.response.data.message)
      setToatAlertErr(true)
    }).finally(() => {
      setNumSelected([])
    })
  }

  const backPage = () => {
    history.goBack()
  }

  useEffect(() => {
    const custData = [{
      "ID": 0,
      "custName": "All"
    }]
    const dataList = customerDropDownStoreStage.state
    dataList.forEach(item => {
      custData.push(item)
    })
    setCustomerDropdown(custData)
  }, [customerDropDownStoreStage.state])

  useEffect(() => {
    register({ name: "lottoType" })
    register({ name: "custID" })
  }, [register])

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={"m-t-14"}>รายงาน</h4>
            </CardHeader>
            <CardBody>
              <GridItem xs={12} sm={12} md={12}>
                <form onSubmit={handleSubmit(loadReport)}>
                  <Autocomplete
                    id="combo-box-demo"
                    margin="normal"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setValue('custID', newValue.ID)
                      }
                    }}
                    options={customerDropdown}
                    getOptionLabel={(option) => option.custName}
                    getOptionSelected={(option) => option.ID}
                    renderInput={(params) => <TextField {...params} label="ผู้ซื้อ/คนเดินโพย" variant="outlined" margin="normal" />} />

                  <FormControl variant="outlined" fullWidth={true} margin="normal">
                    <CustomSelect name="lottoType" label="ประเภทหวย"
                      errors={errors}
                      error={Boolean(errors.lottoType)}
                      control={control} defaultValue="">
                      {lottoTypeItems()}
                    </CustomSelect>
                  </FormControl>

                  <TextField error={errors.lottoNumber ? true : false} name="lottoNumber" variant="outlined" label="ตัวเลข"
                    inputRef={
                      register
                    }
                    fullWidth type="number" margin="normal" />

                  <Button color="rose" type="submit">ค้นหา</Button>
                </form>
              </GridItem>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHeader.map((headCell, key) => {
                        return <TableCell key={key}>{headCell}</TableCell>
                      })}
                      <TableCell key={7} padding="checkbox">
                        <Checkbox
                          indeterminate={numSelected.length > 0 && numSelected.length < rowCount}
                          checked={rowCount > 0 && numSelected.length === rowCount}
                          onChange={onSelectAllClick}
                          inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportDatas.map((value, index) => {
                      const isItemSelected = isSelected(value.ID);
                      const labelId = `enhanced-table-checkbox-${value.ID}`;
                      return (
                        <TableRow hover
                          onClick={(event) => handleClick(event, value.ID)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          key={index}
                          selected={isItemSelected}>

                          <TableCell>
                            {value.lottoTypeDesc}
                          </TableCell>
                          <TableCell>
                            {value.lottoNumber}
                          </TableCell>
                          <TableCell>
                            {value.amount}
                          </TableCell>
                          <TableCell>
                            <Moment format="DD/MM/YYYY HH:mm:ss">
                              {value.CreatedAt}
                            </Moment>
                          </TableCell>
                          <TableCell>
                            {value.custName}
                          </TableCell>
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected}
                              inputprops={{ 'aria-labelledby': labelId }} />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <GridContainer direction="column" alignItems="flex-end">
                <GridItem xs={12} sm={12} md={12}>
                  <Button color="primary" size="lg" onClick={() => { backPage() }}>คีย์ข้อมูลหวย</Button>
                  &nbsp;&nbsp;
                  <Button color="danger" size="lg" onClick={() => { setConfDelOpen(true) }}>ลบ</Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ToastAlert message={errMsg} open={toatAlertErr} onClose={handleToatAlertErr} severity={"error"} />
      <ConfirmDelete open={confDelOpen} handleCloseDialog={handleConfDelClose} handleCallbak={confDelClick} />
    </>
  )
}

function lottoTypeItems() {
  return [
    <MenuItem value="" key={0}>
      All
    </MenuItem>,
    <MenuItem value="2UP" key={1}>สองตัวบน</MenuItem>,
    <MenuItem value="2DOWN" key={2}>สองตัวล่าง</MenuItem>,
    <MenuItem value="2RUNUP" key={3}>วิ่งสองตัวบน</MenuItem>,
    <MenuItem value="2RUNDOWN" key={4}>วิ่งสองตัวล่าง</MenuItem>,
    <MenuItem value="3UP" key={5}>สามตัวบน</MenuItem>,
    <MenuItem value="3DOWN" key={6}>สามตัวล่าง</MenuItem>,
    <MenuItem value="3RUN" key={7}>วิ่งสามตัว</MenuItem>,
    <MenuItem value="3TOD" key={8}>สามตัวโต๊ด</MenuItem>
  ]
}