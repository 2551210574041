import React from 'react'
import { FormHelperText, Link, TextField } from '@material-ui/core'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import RegularButton from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { useForm } from 'react-hook-form'
import { makeStyles } from "@material-ui/core/styles";
import Info from 'components/Typography/Info'
import styles from "assets/jss/material-dashboard-react/components/typographyStyle.js";
import { ax } from 'hooks/UseLoaderAxios'

const useStyles = makeStyles(styles);

export const Register = () => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm()

  const submitRegister = (data) => {
    ax.post('/register', {
    })
  }
  return (
    <GridContainer justify="center">
      <GridItem xs={6} sm={6} md={6}>
        <Card>
          <CardHeader color="primary" className="text-center">
            <h4>สมัครสมาชิก</h4>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit(submitRegister)}>
              <TextField name="username" variant="outlined" label="ชื่อผู้ใช้งาน"
                inputRef={register({ required: true })}
                fullWidth
                margin="normal" />
              <FormHelperText>{errors.username && <span className="error">กรุณากรอกชื่อผู้ใช้งาน</span>}</FormHelperText>
              <TextField name="password" variant="outlined" label="รหัสผ่าน"
                inputRef={register({ required: true })}
                fullWidth
                margin="normal"
                type="password" />
              <FormHelperText>{errors.password && <span className="error">กรุณากรอกรหัสผ่าน</span>}</FormHelperText>
              <RegularButton color="primary" size="lg" type="submit">ตกลง</RegularButton>
            </form>
            <br />
            <GridContainer>
              <GridItem>
                <blockquote className={classes.defaultFontStyle + " " + classes.quote}>
                  <Info>
                    สมัครสมาชิกเสร็จแล้ว กรุณาติดต่อที่ <a href="https://www.facebook.com/%E0%B8%88%E0%B8%B1%E0%B8%94%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B9%80%E0%B8%AA%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%87%E0%B8%A5%E0%B9%87%E0%B8%AD%E0%B8%95%E0%B9%80%E0%B8%95%E0%B8%AD%E0%B8%A3%E0%B8%B5%E0%B9%88-109121444776736" target="_blank" rel="noopener noreferrer">facebook page</a> <a href="https://www.facebook.com/%E0%B8%88%E0%B8%B1%E0%B8%94%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B9%80%E0%B8%AA%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%87%E0%B8%A5%E0%B9%87%E0%B8%AD%E0%B8%95%E0%B9%80%E0%B8%95%E0%B8%AD%E0%B8%A3%E0%B8%B5%E0%B9%88-109121444776736" target="_blank" rel="noopener noreferrer"><b>บันทึกล็อตเตอรี่</b></a> เพื่อแจ้งการชำระค่าบริการ<br />โดยแจ้ง username และอัพโหลดสลิปการชำระเงิน
                    <br />ค่าบริการ<br/>
                    ราคา 200 บาท Login ได้ 1 เครื่อง<br/>
                    ถ้าต้องการ Login พร้อมกันหลายเครื่องคิดเพิ่มเครื่องละ 100 บาท
                  </Info>

                </blockquote>

              </GridItem>
            </GridContainer>
            <Link href="/login">
              ลงชื่อเข้าใช้</Link>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}