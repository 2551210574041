import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React from "react";
import Button from "components/CustomButtons/Button.js";

export default function ConfirmInfo(props) {
  const { open, handleCloseDialog, handleCallbak, title, children } = props
  return (
    <>
      <Dialog open={open} onClick={handleCloseDialog}
        aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{children}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCallbak}>ตกลง</Button>
          <Button onClick={handleCloseDialog} color="danger">ยกเลิก</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}