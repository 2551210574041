import React, { useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

import { Typography } from '@material-ui/core'
import NumberFormat from 'react-number-format'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import Button from "components/CustomButtons/Button"
import Table from "components/Table/Table.js";
import { ConvertDataToTableBody } from './SummaryNumberUtils'
import { ax } from 'hooks/UseLoaderAxios'
import { useHistory } from 'react-router-dom'
import ConfirmInfo from 'components/ConfirmDialog/ConfirmInfo'
import Danger from 'components/Typography/Danger'
import ToastAlert from 'components/ToastAlert/ToastAlert'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'

export const ThreeNumberExport = () => {
  const history = useHistory()
  const tableHead = ["#", "ตัวเลข", "จำนวนเงิน"]
  const [number3UpBody, setNumber3UpBody] = useState([])
  // const [number3DownBody, setNumber3DownBody] = useState([])
  const [number3TodBody, setNumber3TodBody] = useState([])
  const [open, setOpen] = useState(false)
  const [toatAlertErr, setToatAlertErr] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [exportType, setExportType] = useState('')
  const [custData, setCustData] = useState([])
  const [custSelect, setCustSelect] = useState('')
  const autocompleteUp = useRef(null)
  const autocompleteTod = useRef(null)

  const [number3UpStore, setNumber3UpStore] = useState([])
  const [number3TodStore, setNumber3TodStore] = useState([])

  const loadSummaryData = () => {
    ax.post('/lottodatas/summary', {
      "lottoType": "LOTTO_THREE_NUMBER",
      "riskType": "EXPORT"
    }).then(res => {
      const datas = res.data

      setNumber3UpStore(datas.number3Up)
      let number3UpResult = ConvertDataToTableBody(datas.number3Up)
      let number3UpData = number3UpResult.bodys
      number3UpData.push(
        [
          <Typography variant="button" gutterBottom>รวม</Typography>,
          "",
          <NumberFormat value={number3UpResult.totalAmount} displayType={'text'}
            thousandSeparator={true}
            renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />
        ]
      )
      setNumber3UpBody(number3UpData)

      // let number3DownResult = ConvertDataToTableBody(datas.number3Down)
      // let number3DownData = number3DownResult.bodys
      // number3DownData.push(
      //   [
      //     <Typography variant="button" gutterBottom>รวม</Typography>,
      //     "",
      //     <NumberFormat value={number3DownResult.totalAmount} displayType={'text'}
      //       thousandSeparator={true}
      //       renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />
      //   ]
      // )
      // setNumber3DownBody(number3DownData)

      setNumber3TodStore(datas.number3Tod)
      let number3TodResult = ConvertDataToTableBody(datas.number3Tod)
      let number3TodData = number3TodResult.bodys
      number3TodData.push(
        [
          <Typography variant="button" gutterBottom>รวม</Typography>,
          "",
          <NumberFormat value={number3TodResult.totalAmount} displayType={'text'}
            thousandSeparator={true}
            renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />
        ]
      )
      setNumber3TodBody(number3TodData)
    }).catch(err => {
      console.error(err.response)
    })
  }

  const exportExcel = () => {
    ax.post('/lottodatas/summary-export', {
      "lottoType": "LOTTO_THREE_NUMBER",
      "riskType": "EXPORT"
    }, {
      responseType: 'blob'
    }).then(res => {
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', 'สรุปยอดตัดส่งเลขสามตัว.xlsx')
      document.body.appendChild(link)
      link.click()
      link.remove()
    }).catch(err => {
      console.error(err.response)
    })
  }

  const loadCustomer = () => {
    ax.get('/customers-list').then((res) => {
      setCustData(res.data)
    }).catch((err) => {
      console.log(err.response)
    })
  }

  useEffect(loadSummaryData, [])
  useEffect(loadCustomer, [])

  const createLottoData = () => {
    const dataBody = {
      "lottoType": "",
      "custName": "",
      "custID": "",
      "items": []
    }
    if (exportType === 'threeup') {
      const items = number3UpStore.map(i => {
        return { 'lottoNumber': i.lottoNumber, 'amount': -1 * i.amount }
      })
      dataBody.lottoType = "3UP"
      dataBody.items = items
    } else {
      const items = number3TodStore.map(i => {
        return { 'lottoNumber': i.lottoNumber, 'amount': -1 * i.amount }
      })
      dataBody.lottoType = "3TOD"
      dataBody.items = items
    }

    if (!custSelect) {
      setErrMsg("กรุณาเลือกคนเดินโพย")
      setToatAlertErr(true)
      return
    }

    dataBody.custID = custSelect.ID
    dataBody.custName = custSelect.custName

    if (dataBody.items.length > 0) {
      ax.post('/lottodatas/list', dataBody).then((res) => {
        setCustSelect(null);
        clearAutoSelect(dataBody.lottoType)
        clearDataTable();
      }).catch((err) => {
        console.log(err.response)
        let response = err.response
        setErrMsg(response.data.errors.message)
        setToatAlertErr(true)
      })
    }
  }

  const clearDataTable = () => {
    if (exportType === 'threeup') {
      setNumber3UpBody([])
    } else {
      setNumber3TodBody([])
    }
  }

  const clearAutoSelect = (lottoType) => {
    if (lottoType === "3UP") {
      autocompleteUp.current.childNodes[0].childNodes[1].childNodes[1].childNodes[0].click();
    } else {
      autocompleteTod.current.childNodes[0].childNodes[1].childNodes[1].childNodes[0].click();
    }
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4>สรุปยอดตัดส่ง เลขสามตัว</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }} className="print-hide">
                  <Button color="rose" size="lg" className={'m-r-20 card-print'} onClick={() => { loadSummaryData() }}>โหลดข้อมูล</Button>
                  <Button color="success" size="lg" className={'m-r-20 card-print'} onClick={() => { window.print() }}>พิมพ์</Button>
                  <Button color="primary" size="lg" className={"m-r-20 card-print"} onClick={() => { exportExcel() }} >
                    พิมพ์ Excel
                  </Button>
                  <Button color="info" size="lg" className={"m-r-20 card-print"} onClick={() => { history.push("/user/summary/threenumber") }}>
                    กลับ
                  </Button>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} className="print-np">
                  <Card>
                    <CardHeader color="success" className="text-center">
                      <h6>สามตัวบน</h6>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4} className="print-hide">
                          <Button color="danger"
                            onClick={() => {
                              setExportType('threeup')
                              setOpen(true)
                            }}>ตัดยอดเงิน</Button>
                        </GridItem>

                        <GridItem xs={8} sm={8} md={8} className="print-hide">
                          <Autocomplete
                            id="combo-box-demo2"
                            ref={autocompleteUp}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setCustSelect(newValue);
                              }
                            }}
                            options={custData}
                            getOptionLabel={(option) => option.custName}
                            getOptionSelected={(option) => option.ID}
                            renderInput={(params) => <TextField {...params} label="ผู้ซื้อ/คนเดินโพย" variant="outlined" />}
                          />
                        </GridItem>
                      </GridContainer>
                      <Table
                        panelClass="print-tbl"
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={number3UpBody}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem>
                {/* <GridItem xs={4} sm={4} md={4} className="print-np">
                  <Card>
                    <CardHeader color="danger" className="text-center">
                      <h6>สามตัวล่าง</h6>
                    </CardHeader>
                    <CardBody> */}
                {/* <GridContainer>
                        <GridItem xs={4} sm={4} md={4} className="print-hide">
                          <Button color="danger"
                            onClick={() => {
                              setExportType('threedown')
                              setOpen(true)
                            }}>ตัดยอดเงิน</Button>
                        </GridItem>

                        <GridItem xs={8} sm={8} md={8} className="print-hide">
                          <Autocomplete
                            id="combo-box-demo2"
                            ref={autocompleteDown}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setCustSelect(newValue);
                              }
                            }}
                            options={custData}
                            getOptionLabel={(option) => option.custName}
                            getOptionSelected={(option) => option.ID}
                            renderInput={(params) => <TextField {...params} label="ผู้ซื้อ/คนเดินโพย" variant="outlined" />}
                          />
                        </GridItem>
                      </GridContainer> */}
                {/* <Table
                        panelClass="print-tbl"
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={number3DownBody}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem> */}
                <GridItem xs={6} sm={6} md={6} className="print-np">
                  <Card>
                    <CardHeader color="rose" className="text-center">
                      <h6>สามตัวโต๊ด</h6>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4} className="print-hide">
                          <Button color="danger"
                            onClick={() => {
                              setExportType('threetod')
                              setOpen(true)
                            }}>ตัดยอดเงิน</Button>
                        </GridItem>

                        <GridItem xs={8} sm={8} md={8} className="print-hide">
                          <Autocomplete
                            id="combo-box-demo3"
                            ref={autocompleteTod}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setCustSelect(newValue);
                              }
                            }}
                            options={custData}
                            getOptionLabel={(option) => option.custName}
                            getOptionSelected={(option) => option.ID}
                            renderInput={(params) => <TextField {...params} label="ผู้ซื้อ/คนเดินโพย" variant="outlined" />}
                          />
                        </GridItem>
                      </GridContainer>
                      <Table
                        panelClass="print-tbl"
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={number3TodBody}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <ConfirmInfo open={open} handleCloseDialog={() => { setOpen(false) }}
          handleCallbak={createLottoData}
          title="ยืนยันตัดยอดส่งออก">
          <Danger>ระบบจะทำการบันทึกข้อมูลยอดส่งออก โดยนำยอดเงินไปลบออกจากยอดที่คีย์มาในระบบ</Danger>
        </ConfirmInfo>
        <ToastAlert message={errMsg} open={toatAlertErr} onClose={() => { setToatAlertErr(false) }} severity={"error"} />
      </GridContainer>
    </>
  )
}
