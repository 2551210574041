import axios from "axios";
import { Constant } from "Constant";
import urljoin from 'url-join';

const ax = axios.create({ timeout: 120000 })
// Request interceptor for API calls
ax.interceptors.request.use(
    async config => {
        const token = localStorage.getItem("token");
        const apiUrl = process.env.REACT_APP_API_URL;
        config.url = urljoin(apiUrl, config.url);
        config.headers = {
            'Authorization': `Bearer ${token}`
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

const RefreshToken = () => {
    ax.post('/refreshtoken', {
        "uuid": localStorage.getItem(Constant.UUID)
    }).then(res => {
        localStorage.setItem(Constant.TOKEN, res.data.result.access_token)
    }).catch((res) => { console.error(res.response) })
}

export {
    RefreshToken
}