import React, { useReducer } from "react";
import { createContext } from "react";

const initialState = []
const customerDropdownStore = createContext(initialState);
const { Provider } = customerDropdownStore;

const StateCustomerDropdown = ({ children }) => {
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case 'add':
                const newState = action.data
                return newState
            default:
                throw new Error("Action type not found");
        }
    }, initialState)

    return (
        <Provider value={{state, dispatch}}>{children}</Provider>
    );
}

export {customerDropdownStore, StateCustomerDropdown}