import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import isUserAuthenticated from 'utils/auth'

function PrivateRoute({ component: Component, ...rest }){
  
    return (
        <Route exact
          {...rest}
          render={props =>
            isUserAuthenticated() ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location }
                }}
              />
            )
          }
        />
      );
}
export default PrivateRoute;