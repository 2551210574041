import React from "react";
import { Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"

const ToastAlert = (props) => {
    const { message, onClose, open, severity } = props
    return (
        <Snackbar open={open} autoHideDuration={10000} onClose={onClose}>
            <Alert onClose={onClose} severity={(severity) ? severity : "success"} variant="filled" style={{ width: 700 }}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default ToastAlert;