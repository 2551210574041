import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";

export default function ConfirmDelete(props){
    const {open, handleCloseDialog, handleCallbak} = props
    return (
        <Dialog open={open} onClick={handleCloseDialog}
        aria-labelledby="form-dialog-title" fullWidth={true}
        maxWidth="sm">
            <DialogTitle>ยืนยันการลบ</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    คุณต้องการลบข้อมูลหรือไม่
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                    <Button color="primary" onClick={handleCallbak}>ตกลง</Button>
                    <Button onClick={handleCloseDialog} color="danger">ยกเลิก</Button>
            </DialogActions>
        </Dialog>
    )
}