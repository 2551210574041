import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

import { Typography } from '@material-ui/core'
import NumberFormat from 'react-number-format'

import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from "components/CustomButtons/Button"
import Table from "components/Table/Table.js";
import { ConvertDataToTableBody } from './SummaryNumberUtils'
import { ax } from 'hooks/UseLoaderAxios'
import { useHistory } from 'react-router-dom'

export const ThreeNumberKeep = () => {
  const tableHead = ["#", "ตัวเลข", "จำนวนเงิน"]
  const history = useHistory()
  const [number3UpBody, setNumber3UpBody] = useState([])
  // const [number3DownBody, setNumber3DownBody] = useState([])
  const [number3TodBody, setNumber3TodBody] = useState([])

  const loadSumData = () => {

    ax.post('/lottodatas/summary', {
      "lottoType": "LOTTO_THREE_NUMBER",
      "riskType": "KEEP"
    }).then(res => {
      const datas = res.data

      let number3UpResult = ConvertDataToTableBody(datas.number3Up)
      let number3UpData = number3UpResult.bodys
      number3UpData.push(
        [
          <Typography variant="button" gutterBottom>รวม</Typography>,
          "",
          <NumberFormat value={number3UpResult.totalAmount} displayType={'text'}
            thousandSeparator={true}
            renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />
        ]
      )
      setNumber3UpBody(number3UpData)

      // let number3DownResult = ConvertDataToTableBody(datas.number3Down)
      // let number3DownData = number3DownResult.bodys
      // number3DownData.push(
      //   [
      //     <Typography variant="button" gutterBottom>รวม</Typography>,
      //     "",
      //     <NumberFormat value={number3DownResult.totalAmount} displayType={'text'}
      //       thousandSeparator={true}
      //       renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />
      //   ]
      // )
      // setNumber3DownBody(number3DownData)

      let number3TodResult = ConvertDataToTableBody(datas.number3Tod)
      let number3TodData = number3TodResult.bodys
      number3TodData.push(
        [
          <Typography variant="button" gutterBottom>รวม</Typography>,
          "",
          <NumberFormat value={number3TodResult.totalAmount} displayType={'text'}
            thousandSeparator={true}
            renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />
        ]
      )
      setNumber3TodBody(number3TodData)
    }).catch(err => {
      console.error(err.response)
    })
  }

  const exportExcel = () => {
    ax.post('/lottodatas/summary-export', {
      "lottoType": "LOTTO_THREE_NUMBER",
      "riskType": "KEEP"
    }, {
      responseType: 'blob'
    }).then(res => {
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', 'สรุปยอดตัดเก็บเลขสามตัว.xlsx')
      document.body.appendChild(link)
      link.click()
      link.remove()
    }).catch(err => {
      console.error(err.response)
    })
  }

  useEffect(loadSumData, [])

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4>สรุปยอดตัดเก็บ เลขสามตัว</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }} className="print-hide">
                  <Button color="success" size="lg" className={'m-r-20 card-print'} onClick={() => { window.print() }}>พิมพ์</Button>
                  <Button color="primary" size="lg" className={"m-r-20 card-print"} onClick={() => { exportExcel() }} >
                    พิมพ์ Excel
                  </Button>
                  <Button color="info" size="lg" className={"m-r-20 card-print"} onClick={() => { history.push("/user/summary/threenumber") }}>
                    กลับ
                  </Button>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} className="print-np">
                  <Card>
                    <CardHeader color="success" className="text-center">
                      <h6>สามตัวบน</h6>
                    </CardHeader>
                    <CardBody>
                      <Table
                        panelClass="print-tbl"
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={number3UpBody}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem>
                {/* <GridItem xs={4} sm={4} md={4} className="print-np">
                  <Card>
                    <CardHeader color="danger" className="text-center">
                      <h6>สามตัวล่าง</h6>
                    </CardHeader>
                    <CardBody>
                      <Table
                        panelClass="print-tbl"
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={number3DownBody}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem> */}
                <GridItem xs={6} sm={6} md={6} className="print-np">
                  <Card>
                    <CardHeader color="rose" className="text-center">
                      <h6>สามตัวโต๊ด</h6>
                    </CardHeader>
                    <CardBody>
                      <Table
                        panelClass="print-tbl"
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={number3TodBody}>
                      </Table>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  )
}
