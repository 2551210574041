import React from "react";
import { useEffect } from "react";
import { useState } from "react";

// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import DeleteIcon from '@material-ui/icons/Delete';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import CustomTable from "components/Table/Table";
import NumberFormat from "react-number-format";
import Button from "components/CustomButtons/Button";
import ToastAlert from "components/ToastAlert/ToastAlert";
import { ax } from "hooks/UseLoaderAxios";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles(styles);

export default function Dashboard() {
    const twoNumbers = '2';
    const threeNumbers = '3';
    const allNumbers = '0';
    const classes = useStyles();

    const [tableData, setTableData] = useState([])
    const [totalOrderAmt, setTotalOrderAmt] = useState(0)
    const [openToastAlert, setOpenToastAlert] = useState(false)
    const [openToastErr, setOpenToastErr] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [confDelOpen, setConfDelOpen] = useState(false)
    const [delType, setDelType] = useState('')

    const { register, handleSubmit, errors } = useForm({ mode: "onBlur" })

    const loadSummaryOrder = () => {
        ax.get("/dashboard/user/sum-orders").then((res) => {
            let orderData = res.data.sumOrderAmountResult;
            let amountItems = orderData.sumOrderAmountItems

            let dataList = []
            dataList.push([
                <NumberFormat value={amountItems[0].amount} displayType={'text'}
                    thousandSeparator={true}
                    renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />,
                <NumberFormat value={amountItems[1].amount} displayType={'text'}
                    thousandSeparator={true}
                    renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />,
                <NumberFormat value={amountItems[2].amount} displayType={'text'}
                    thousandSeparator={true}
                    renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />,
                <NumberFormat value={amountItems[3].amount} displayType={'text'}
                    thousandSeparator={true}
                    renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />,
                <NumberFormat value={amountItems[4].amount} displayType={'text'}
                    thousandSeparator={true}
                    renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />,
                <NumberFormat value={amountItems[5].amount} displayType={'text'}
                    thousandSeparator={true}
                    renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />,
                <NumberFormat value={amountItems[6].amount} displayType={'text'}
                    thousandSeparator={true}
                    renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />,
                <NumberFormat value={amountItems[7].amount} displayType={'text'}
                    thousandSeparator={true}
                    renderText={value => <Typography variant="button" gutterBottom>{value}</Typography>} />,

            ])
            setTableData(dataList)
            setTotalOrderAmt(orderData.sumOrderTotalAmount)
        }).catch((err) => {
            console.error(err)
        })
    }

    const tableHeads = ['สองตัวบน', 'สองตัวล่าง', 'วิ่งสองตัวบน', 'วิ่งสองตัวล่าง', 'สามตัวบน', 'สามตัวล่าง', 'สามตัวโต๊ด', 'วิ่งสามตัว']

    const deleteLottoDataType = (lottoType) => {
        setConfDelOpen(false)
        ax.post('/lottodatas/deletes', {
            'lottoType': delType,
            'passwordDel': lottoType.passwordDel
        }).then(res => {
            setOpenToastAlert(true)
            loadSummaryOrder()
        }).catch(err => {
            setErrMsg(JSON.stringify(err.response.data))
            setOpenToastErr(true)
        })
    }

    useEffect(loadSummaryOrder, []);
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>
                                สรุปยอดซื้อ
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <CustomTable tableHead={tableHeads} tableData={tableData} />
                            <GridContainer
                                direction="column"
                                justifyContent="center"
                                alignItems="center">
                                <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
                                    <span className={"sum-label"}>ยอดรวมทั้งหมด <NumberFormat value={totalOrderAmt} thousandSeparator={true} displayType={'text'} /> บาท</span>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
                                    <Button color="info" size="lg"
                                        onClick={() => {
                                            setConfDelOpen(true)
                                            setDelType(twoNumbers)
                                        }}>
                                        {/* () => { deleteLottoDataType(twoNumbers) } */}
                                        <DeleteIcon />ล้างข้อมูลเลข 2 ตัว
                                    </Button>
                                    &nbsp;
                                    <Button color="info" size="lg"
                                        onClick={() => {
                                            setConfDelOpen(true)
                                            setDelType(threeNumbers)
                                        }}>
                                        {/* deleteLottoDataType(threeNumbers) */}
                                        <DeleteIcon />ล้างข้อมูลเลข 3 ตัว
                                    </Button>
                                    &nbsp;
                                    <Button color="rose" size="lg"
                                        onClick={() => {
                                            setConfDelOpen(true)
                                            setDelType(allNumbers)
                                        }}>
                                        {/* deleteLottoDataType(allNumbers) */}
                                        <DeleteIcon />ล้างข้อมูลทั้งหมด
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </CardBody>

                    </Card>

                </GridItem>

            </GridContainer>
            <ToastAlert message="ลบข้อมูลสำเร็จ" open={openToastAlert} onClose={() => { setOpenToastAlert(false) }} />
            <ToastAlert message={errMsg} open={openToastErr} onClose={() => { setOpenToastErr(false) }} severity="error"/>

            <Dialog open={confDelOpen} aria-labelledby="form-dialog-title" fullWidth={true}
                maxWidth="sm">
                <form id="confirm-form" className={classes.formControl} onSubmit={handleSubmit(deleteLottoDataType)}>
                    <DialogTitle>ยืนยันการลบ</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            คุณต้องการลบข้อมูลหรือไม่
                        </DialogContentText>
                        <TextField autoFocus name="passwordDel"
                            label="รหัสผ่านลบข้อมูล"
                            inputRef={register({required: true})}
                            margin="normal"
                            type="password"
                            fullWidth
                            error={errors.passwordDel ? true : false} />
                        <FormHelperText >{errors.passwordDel && <span className="error">กรุณาระบุรหัสผ่าน</span>}</FormHelperText>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" color="primary">ตกลง</Button>
                        <Button onClick={() => { setConfDelOpen(false) }} color="danger">ยกเลิก</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
