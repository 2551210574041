import { Constant } from "Constant";
import { ax } from "hooks/UseLoaderAxios";

const LogoutService = async () =>{
  await ax.post("/logout", {
    "uuid": localStorage.getItem(Constant.UUID)
  }).then(()=>{}).catch((res)=>{console.error(res.response)})
  localStorage.clear()
  window.location = "/login"
}

export default LogoutService;