import { FormHelperText, TextField } from '@material-ui/core'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import RegularButton from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import React from 'react'

import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import ToastAlert from 'components/ToastAlert/ToastAlert'
import { useState } from 'react'
import { ax } from 'hooks/UseLoaderAxios'

export const ChangePassword = () => {
    const [openSaveCustSuccess, setOpenSaveCustSuccess] = useState(false)
    const [severity, setSeverity] = useState('success')
    const [toastMsg, setToastMsg] = useState('')
    const { handleSubmit, register, errors, watch } = useForm()
    const password = useRef({})
    password.current = watch('password', '')

    const submitChangePassword = (data) => {
        ax.put('/myprofile/password', {
            "password": data.password,
            "oldPassword": data.oldPass
        }).then(() => {
            setToastMsg("เปลี่ยนรหัสผ่านสำเร็จ")
            setSeverity("success")
            setOpenSaveCustSuccess(true)
        }).catch((err) => {
            let response = err.response
            if (response) {
                setToastMsg(response.data.errors.message)
            } else {
                setToastMsg("เกิดข้อผิดพลาด")
            }
            setSeverity("error")
            setOpenSaveCustSuccess(true)
        })
    }

    return (
        <>
            <GridContainer justifyContent="center">
                <GridItem xs={12} sm={12} md={8}>
                    <Card>
                        <CardHeader color="primary" className="text-center">
                            <h4>เปลี่ยนรหัสผ่าน</h4>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(submitChangePassword)}>
                                <TextField name="oldPass" variant="outlined" label="รหัสผ่านเดิม"
                                    inputRef={register({ required: true })}
                                    fullWidth
                                    type="password"
                                    margin="normal" />
                                <FormHelperText>{errors.username && <span className="error">กรุณากรอก รหัสผ่านเดิม</span>}</FormHelperText>

                                <TextField name="password" variant="outlined" label="รหัสผ่านใหม่"
                                    inputRef={register({ required: true })}
                                    fullWidth
                                    type="password"
                                    margin="normal" />
                                <FormHelperText>{errors.password && <span className="error">กรุณากรอก รหัสผ่านใหม่</span>}</FormHelperText>
                                <TextField name="passwordRepeat" variant="outlined" label="ยืนยันรหัสผ่านใหม่"
                                    inputRef={register({
                                        validate: value => value === password.current || "รหัสผ่านไม่ตรงกัน"
                                    })}
                                    fullWidth
                                    type="password"
                                    margin="normal" />
                                <FormHelperText>{errors.passwordRepeat && <span className="error">{errors.passwordRepeat.message}</span>}</FormHelperText>
                                <RegularButton color="primary" size="lg" type="submit">ยืนยัน</RegularButton>
                            </form>

                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <ToastAlert message={toastMsg} open={openSaveCustSuccess} onClose={() => {
                setOpenSaveCustSuccess(false)
            }} severity={severity} />
        </>
    )
}